import React, { useState, useEffect, useRef, useCallback } from 'react';
import './clientEsriSelection.css';
import Header from '../../components/header';
import ParticleBackground from '../../components/particlesbackgroundForScrollable';
import { loadFull } from 'tsparticles';
import { loadPolygonMaskPlugin } from 'tsparticles-plugin-polygon-mask';
import type { Engine } from 'tsparticles-engine';

// Components
import { useAuth } from '../../Auth';

// Assets
import tempImage from '../../img/mission/LightningBg.jpeg';

// Icons
import { IoIosLink } from "react-icons/io";
import { IoPlayCircleOutline } from "react-icons/io5";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

// Interface for reading image
interface EsriLinks {
    GROUP_NAME: string;
    GROUP_ESRI_LINK: any;
}

// Interface for reading image
interface EsriPhotos {
    imgAlt: string;
    img: any;
}

const ClientEsriSelection = () => {
    const [linkData, setLinkData] = useState<EsriLinks[]>([]);
    const [imageData, setImageData] = useState<EsriPhotos[]>([]);
    const { isAuthenticated, getDashboardPermissions } = useAuth();
    const [clientPermissionGroups, setClientPermissionGroups] = useState<{ [key: string]: any }>({});
    const [expandedCards, setExpandedCards] = useState<{ [key: string]: string | null }>({});
    const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});

    // Ensure the page always start at the top
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);

    // Update expandedGroups when clientPermissionGroups changes
    useEffect(() => {
        if (Object.keys(clientPermissionGroups).length === 1) {
            const singleGroup = Object.keys(clientPermissionGroups)[0];
            setExpandedGroups({ [singleGroup]: true });
        }
    }, [clientPermissionGroups]); // Runs when clientPermissionGroups updates

    // Fetching links
    useEffect(() => {
        const fetchESRILinks = async () => {
            try {
                const response = await fetch('/api/detectordashapi/', {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: 'same-origin',
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data: EsriLinks[] = await response.json();
                const parsedData = data.map(item => ({
                    ...item,
                    GROUP_ESRI_LINK: JSON.parse(item.GROUP_ESRI_LINK) // Convert string to object
                }));

                setLinkData(parsedData);
            } catch (err) {
                if (err instanceof Error) {
                    console.error(err.message);
                } else {
                    console.error('An unknown error occurred');
                }
            }
        };

        fetchESRILinks();
    }, []);

    // Fetching from Client Groups
    useEffect(() => {
        const fetchClientPermissions = async () => {
            const permissions2 = await getDashboardPermissions();
            setClientPermissionGroups(permissions2 || {});
        };

        if (isAuthenticated) {
            fetchClientPermissions();
        }
    }, [isAuthenticated, getDashboardPermissions]);

    // Fetching images
    useEffect(() => {
        const fetchESRIImages = async () => {
            try {
                const response = await fetch('/api/detectordashlinksimgs/', {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: 'same-origin',
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data: EsriPhotos[] = await response.json();

                setImageData(data);
            } catch (err) {
                if (err instanceof Error) {
                    console.error(err.message);
                } else {
                    console.error('An unknown error occurred');
                }
            }
        };

        fetchESRIImages();
    }, []);

    // Effect to set default expanded card for single card groups
    useEffect(() => {
        linkData.forEach((linkItem) => {
            const dashboardLinks = linkItem.GROUP_ESRI_LINK;
            const cardKeys = Object.keys(dashboardLinks);

            if (cardKeys.length === 1) {
                const defaultCardId = `${linkItem.GROUP_NAME}-0`;
                setExpandedCards((prev) => ({
                    ...prev,
                    [linkItem.GROUP_NAME]: defaultCardId, // Expand the single card by default
                }));
            }
        });
    }, [linkData]); // Only run when linkData changes

    const handleCardClick = (section: string, index: number) => {
        const cardId = `${section}-${index}`;
        setExpandedCards((prev) => ({
            ...prev,
            [section]: prev[section] === cardId ? null : cardId, // Toggle expansion per section
        }));

        // Scroll to the clicked card when it expands with an offset
        setTimeout(() => {
            const element = document.getElementById(cardId);
            if (element) {
                const yOffset = -180; // Adjust this value for the desired offset
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({ top: y });
            } else {
                console.warn("Element not found:", cardId);
            }
        }, 1); // Delay to allow expansion animation to start
    };

    // Partices Background
    const particlesInit = useCallback(async (main: Engine) => {
        await loadFull(main);
        await loadPolygonMaskPlugin(main);
    }, []);

    const [width, setWidth] = useState(window.innerWidth);
    const [heightTopParticles, setHeightTopParticles] = useState(width * 0.36);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeightTopParticles(width * 0.36);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [width]);


    // Toggle visibility of cards when <h1> is clicked
    const toggleGroupExpansion = (groupName: string) => {
        setExpandedGroups((prev) => ({
            ...prev,
            [groupName]: !prev[groupName], // Toggle visibility of the group
        }));
    };

    const renderCards = (section: string, dashboardLinks: any) => {
        const cardKeys = Object.keys(dashboardLinks);

        return (
            <div className='esriWrapper'>
                {cardKeys.map((dashboardName, index) => {
                    // Declaring variables for links database
                    const dashboard = dashboardLinks[dashboardName];
                    const cardId = `${section}-${index}`;

                    // Declaring variables for handling hiding and expanding cards on click
                    const isExpanded = expandedCards[section] === cardId;
                    const isHidden = expandedCards[section] && !isExpanded;

                    // Declaring variables for image database
                    const matchingImage = imageData.find((img) => img.imgAlt === dashboardName);
                    const imageUrl = matchingImage ? matchingImage.img : tempImage;

                    // console.log("This is the card ID: ", cardId)

                    return (
                        <div
                            key={cardId}
                            id={cardId}
                            className={`esriCard ${isExpanded ? 'expanded' : isHidden ? 'hidden' : ''}`}
                            onClick={() => handleCardClick(section, index)}
                        >
                            <div className={`esriImg ${isExpanded ? 'no-hover' : ''}`} style={{ backgroundImage: `url(${imageUrl})` }}>
                                <div className="esriImg-overlay">
                                    <span className="esriImg-text-static">
                                        {dashboardName}
                                    </span>

                                    <span className={`esriImg-text ${isExpanded ? 'disable-hover' : ''}`}>
                                        < MdKeyboardDoubleArrowRight className='arrowIconClass' />
                                    </span>

                                    {isExpanded && (
                                        <div className='esriLinks'>
                                            <div className="esriLinks">
                                                <div className='esriImg-text-static2'>{dashboardName}</div>
                                                {dashboard.url &&
                                                    <a href={dashboard.url} target="_blank" rel="noopener noreferrer" className="esriLink">
                                                        <IoIosLink className="linkIcons" /> Dashboard
                                                    </a>
                                                }
                                                {dashboard.pdf &&
                                                    <a href={dashboard.pdf} target="_blank" rel="noopener noreferrer" className="esriLink">
                                                        <IoInformationCircleOutline className="linkIcons" /> User-Guide
                                                    </a>
                                                }
                                                {dashboard.video &&
                                                    <a href={dashboard.video} target="_blank" rel="noopener noreferrer" className="esriLink">
                                                        <IoPlayCircleOutline className="linkIcons" /> Demo Video
                                                    </a>
                                                }
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            <Header />

            <ParticleBackground
                particlesInitBackground={particlesInit}
                id="topParticles"
                number={80}
                aspectRatio={width / heightTopParticles}
            />

            <div className='overall-esriWrapper'>
                {linkData
                    .sort((a, b) => a.GROUP_NAME.localeCompare(b.GROUP_NAME)) // Sort by GROUP_NAME
                    .map((linkItem) => {
                        if (clientPermissionGroups[linkItem.GROUP_NAME]) {
                            return (
                                <div key={linkItem.GROUP_NAME} className='esriTitleAndWrapper'>
                                    <h1 onClick={() => toggleGroupExpansion(linkItem.GROUP_NAME)}>
                                        {linkItem.GROUP_NAME.replace('detectors_', '')
                                            .replace('_', ' ')
                                            .toLowerCase()
                                            .replace(/\b\w/g, char => char.toUpperCase())}
                                        {expandedGroups[linkItem.GROUP_NAME] ? <MdExpandLess /> : <MdExpandMore />}
                                    </h1>
                                    {expandedGroups[linkItem.GROUP_NAME] && renderCards(linkItem.GROUP_NAME, linkItem.GROUP_ESRI_LINK)}
                                </div>
                            );
                        }
                        return null;
                    })}
            </div>

        </div>
    );
};

export default ClientEsriSelection;
