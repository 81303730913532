import React from "react";

export function interpolateColor(
    value: number, 
    min: number, 
    max: number,
    includeRanges = false // Optional parameter to control if the ranges should be included
): { color: string, rangeCloserToMin?: string, rangeCloserToMax?: string } { // Optional properties
    // Calculate log values for min, max, and the given value
    const logMin = Math.log(min + 1);
    const logMax = Math.log(max + 1);
    const logValue = Math.log(value + 1);

    // Calculate the normalized ratio based on logarithmic scale
    const ratio = (logValue - logMin) / (logMax - logMin);

    // Interpolate the color based on the ratio
    let r = 0, g = 0, b = 0;

    if (ratio <= 0.25) {
        // Interpolate from blue (rgb(0, 0, 255)) to green (rgb(0, 255, 0))
        b = 255;
        g = Math.round(255 * (ratio / 0.25)); // Green increases as ratio increases
    } else if (ratio <= 0.55) {
        // Interpolate from green (rgb(0, 255, 0)) to yellow (rgb(255, 255, 0))
        g = 255;
        r = Math.round(255 * ((ratio - 0.25) / 0.3)); // Red increases as ratio increases
    } else if (ratio <= 0.85) {
        // Interpolate from yellow (rgb(255, 255, 0)) to red (rgb(255, 0, 0))
        r = 255;
        g = Math.round(255 * (1 - (ratio - 0.55) / 0.3)); // Green decreases as ratio increases
    } else {
        // Interpolate from red (rgb(255, 0, 0)) to red (rgb(255, 0, 0))
        r = 255;
        g = 0;
        b = 0;
    }

    // Initialize the return object
    const result: { color: string, rangeCloserToMin?: string, rangeCloserToMax?: string } = {
        color: `rgb(${r},${g},${b})` // Always return color
    };

    // Only calculate ranges if includeRanges is true
    if (includeRanges) {
        // Midpoint where color shifts from green → yellow
        const logRangeCloserToMinEnd = (logMin + logMax) / 2;  
    
        // Upper range where color shifts from yellow → red
        const logRangeCloserToMaxEnd = logMax - (logMax - logMin) * 0.3; 
    
        // Convert back to normal scale
        const rangeCloserToMin = Math.exp(logRangeCloserToMinEnd) - 1;
        const rangeCloserToMax = Math.exp(logRangeCloserToMaxEnd) - 1;
    
        result.rangeCloserToMin = `${Math.round(rangeCloserToMin)}`;
        result.rangeCloserToMax = `${Math.round(rangeCloserToMax)}`;
    }

    return result;
}

interface LegendProps {
    min: number;
    max: number;
}

const GradientLegend: React.FC<LegendProps> = ({ min, max }) => {
    const gradientSteps = 10;
    
    // Distribute values logarithmically between min and max
    const gradientColors = Array.from({ length: gradientSteps }, (_, i) => {
        // Calculate the logarithmic value for the current step
        const logMin = Math.log(min + 1);
        const logMax = Math.log(max + 1);
        const logStep = logMin + ((logMax - logMin) * i) / (gradientSteps - 1);

        // Convert the log value back to the original scale
        const value = Math.exp(logStep) - 1; // Exp to reverse the log transformation

        // Get the interpolated color and ranges for display
        return interpolateColor(value, min, max, true);
    });

    // Get the last item from the array to access the color and ranges
    const { rangeCloserToMin, rangeCloserToMax } = gradientColors[gradientColors.length - 1];

    return (
        <div
            style={{
                boxShadow: "0 0 6px rgba(0,0,0,0.2)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "0.8rem",
                color: "white",
            }}
        >
            <div style={{ marginBottom: "5px", fontSize: "1rem" }}>Strikes</div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem"
                }}
            >
                <div
                    style={{
                        width: "20px",
                        height: "9.375rem",
                        background: `linear-gradient(to top, ${gradientColors.map(g => g.color).join(", ")})`,
                        border: "1px solid #ccc",
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "9.5rem",
                    }}
                >
                    <span>{max}</span>
                    <span>{rangeCloserToMax}</span>
                    <span>{rangeCloserToMin}</span>
                    <span>{min}</span>
                </div>
            </div>
        </div>
    );
};

export default GradientLegend;
