import Particles from 'react-tsparticles';
import type { ISourceOptions } from 'tsparticles-engine';
import { useState, useEffect } from 'react';


export default function ParticleBackground(props: any) {
  const particlesInitBackground = props.particlesInitBackground;
  const id = props.id;
 
  

  const [width, setWidth] = useState(window.innerWidth);
  const [number, setNumber] = useState(props.number);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      calculateSize(width);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  
  const aspectRatio = (props.aspectRatio ? props.aspectRatio: width/500); // width / height
  function calculateSize(width: number) {
    if (aspectRatio > 1 && width < 800) {
      setSizeoptions({
        number: (width * number) / (1920*aspectRatio),
      });
    }
    else{
      setSizeoptions({
        number: (width * number) / 1920,
      });
    }   
  }


  const [sizeoptions, setSizeoptions] = useState({
    number: (width * number) / (1920 * aspectRatio * 500/width),
  });

  const options = {
    fullScreen: {
      enable: false,
      zIndex: -1,
    },
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: 'repulse',
        },
        onDiv: {
          elementId: 'repulse-div',
          enable: false,
          mode: 'repulse',
        },
        onHover: {
          enable: true,
          mode: 'bubble',
          parallax: {
            enable: false,
            force: 2,
            smooth: 10,
          },
        },
      },
      modes: {
        bubble: {
          distance: 40,
          duration: 2,
          opacity: 8,
          size: 6,
        },
        repulse: {
          distance: 250,
          duration: 0.2,
        },
      },
    },
    particles: {
      color: {
        value: '#28aae1',
      },
      links: {
        blink: true,
        color: '#ffffff',
        consent: false,
        distance: 200,
        enable: true,
        opacity: 0.2,
        width: 1,
      },
      move: {
        enable: true,
        outModes: 'bounce',
        speed: 0.15,
      },
      number: {
        limit: 0,
        value: sizeoptions.number,
      },
      opacity: {
        animation: {
          enable: true,
          speed: 2,
          sync: false,
        },
        value: {
          min: 0.05,
          max: 0.3,
        },
      },
      shape: {
        type: 'circle',
      },
      size: {
        value: 2,
      },
    },
    background: {
      color: '',
      image: '',
      position: '50% 50%',
      repeat: 'no-repeat',
      size: 'cover',
    },
  }as ISourceOptions;

  return (
    <>
      <Particles id={id} options={options} init={particlesInitBackground}  />
    </>
  );
}
