import React, { Fragment, useEffect, useState } from 'react';
import { useAuth } from '../Auth';
import '../backend/css/client-login-card.css'

// Assets
import detectorCardPicture from '../img/detector/2025Detector4.jpg'
import esriDashCardPicture from '../img/mapping/background.jpg'
import backendBackground from '../img/detector/backend-img.jpg'
import inventoryBackground from '../img/inventory/inventory-img1.png'

// Notification
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css';

interface CardProps {
  title: string;
  subtitle?: string; // Optional subtitle
  text?: string;
  link1: string;
  link2?: string;
  link1Text: string;
  link2Text?: string;
}

const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  text,
  link1,
  link2,
  link1Text,
  link2Text,
}) => {
  return (
    <div className="card text-white bg-dark mb-3" style={{ width: '18rem' }}>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        {subtitle && (
          <h6 className="card-subtitle mb-2 ">{subtitle}</h6>
        )}
        {text && <p className="card-text">{text}</p>}
        <a href={link1} className="btn background-primary">
          {link1Text}
        </a>
        {link2 && (
          <a href={link2} className="card-link">
            {link2Text}
          </a>
        )}
      </div>
    </div>
  );
};

const fnnbackgroundcard = {
  title: "Detector Monitoring",
  subtitle: "Status and Files",
  link1: "/fnnbackend",
  link1Text: "Check Detectors",
}

const LoginRoutes: React.FC = () => {
  const { isAuthenticated, getPermissions, getDashboardPermissions } = useAuth();
  const [permissionGroups, setPermissionGroups] = useState<{ [key: string]: any }>({});
  const [clientPermissionGroups, setClientPermissionGroups] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const fetchPermissions = async () => {
      const permissions = await getPermissions(); // Await the promise
      setPermissionGroups(permissions || {});
    };

    if (isAuthenticated) {
      fetchPermissions();
    }
  }, [isAuthenticated, getPermissions]);

  useEffect(() => {
    const fetchClientPermissions = async () => {
      const permissions2 = await getDashboardPermissions(); // Await the promise
      setClientPermissionGroups(permissions2 || {});
    };

    if (isAuthenticated) {
      fetchClientPermissions();
    }
  }, [isAuthenticated, getDashboardPermissions]);

  const cardItems = [
  permissionGroups.DetectorTeam && {
    href: "/fnnbackend",
    imgSrc: backendBackground,
    title: "FNN Backend",
  },
  permissionGroups.InventoryTeam && {
    href: "https://www.notion.so/Inventory-Management-1a63634fc8d980ab8559e08406c99c7d",
    imgSrc: inventoryBackground,
    title: "Detector Inventory Management",
  },
  Object.keys(clientPermissionGroups).length > 0 && {
    href: "/fnn_client_dashboard_map",
    imgSrc: detectorCardPicture,
    title: "Detector Status Dashboard",
  },
  Object.keys(clientPermissionGroups).length > 0 && {
    // onClick: () => (groups.length === 1 ? handleCardClick() : showPopup()),
    href: "/fnn_client_dashboard_esri",
    imgSrc: esriDashCardPicture,
    title: "ESRI Lightning Dashboard",
  },
].filter(Boolean); // Remove falsy values (like false or null)

return (
  <div>
    <ToastContainer />
              
    <div className={`card-stack ${cardItems.length >= 4 ? "grid-layout" : ""}`}>
      {cardItems.map((item, index) => (
        <a
          key={index}
          href={item.href || "#"}
          onClick={item.onClick}
          className="info-card"
          target={item.href?.startsWith("http") ? "_blank" : undefined}
          rel={item.href?.startsWith("http") ? "noopener noreferrer" : undefined}
        >
          <img src={item.imgSrc} alt=" " className="card-img" />
          <h1>{item.title}</h1>
        </a>
      ))}
    </div>
  </div>
);

};

export default LoginRoutes;
