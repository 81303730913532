import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Add this
import PropTypes from "prop-types";
import "../client-dashboard/clientDashboardMap.css";
import "leaflet.heat"; // Ensure this is installed
import HeatmapOverlay from "leaflet-heatmap"; // Import HeatmapOverlay
import GradientLegend from "./cilent-dashboard-util/GradientLegend";

// OBS: IF YOU WANT TO TEST THIS COMPONENT INDEPENDENTLY GO TO http://localhost:3000/heatmap but make sure to add it to index.tsx

import { removeIrrelevantRangesEllipse } from "./lightningData";

export async function heatMapData() {
  const data = await removeIrrelevantRangesEllipse(); // Wait for the data

  // Convert the Map to an array of entries and process it
  return Array.from(data.entries()).map(([key, count]) => {
    const range = JSON.parse(key); // Convert string key to array
    const midLat = (range[0][0] + range[2][0]) / 2;
    const midLon = (range[0][1] + range[2][1]) / 2;
    return [midLon, midLat, count];
  });
}

export const HeatMapLayer = ({ data, onExtremaChange }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const cfg = {
      radius: 0.125,  // Increased radius to ensure more visible heatmap blobs
      maxOpacity: 0.7,  // Set maxOpacity to make the heatmap more visible
      minOpacity: 0.1,  // Set minOpacity to avoid too much fading of points
      scaleRadius: true,
      useLocalExtrema: true,
      latField: "lat",
      lngField: "lng",
      valueField: "count",
      // Add the onExtremaChange callback to log min/max and update parent
      onExtremaChange: (data) => {
        onExtremaChange(data.min, data.max); // Pass min and max to parent
      },
    };

    const heat = new HeatmapOverlay(cfg);
    heat.setData({
      max: Math.max(...data.map(d => d[2])), // Set max value for heatmap
      data: data.map(([lat, lng, count]) => ({ lat, lng, count })),
    });

    map.addLayer(heat);

    return () => {
      map.removeLayer(heat);
    };
  }, [map, data, onExtremaChange]);

  return null;
};

HeatMapLayer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  onExtremaChange: PropTypes.func.isRequired, // Ensure the parent function is passed
};

HeatMapLayer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
};

export const HeatMapMap = () => {
  const [heatmapData, setHeatmapData] = useState([]);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const data = await heatMapData();
      setHeatmapData(data);
    };

    loadData();
  }, []);

  const center = [-19.86953, -43.83895];

  const handleExtremaChange = (newMin, newMax) => {
    setMin(newMin);
    setMax(newMax);
  };

  return (
    <MapContainer
      center={center}
      zoom={11}
      style={{ height: "1000px", width: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <HeatMapLayer data={heatmapData} onExtremaChange={handleExtremaChange} />
      <div className={`lightning-info-legend`}>
        <div className="lightning-info-legend-inner">
          <GradientLegend min={1} max={max} />
        </div>
      </div>
    </MapContainer>
  );
};

export default HeatMapMap;

