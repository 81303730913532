import React from 'react'

import { TbCalendarPlus } from "react-icons/tb";
import { MdOutlineEditCalendar } from "react-icons/md";

interface NoteProps {
    // Editing: DetectorEditing;
    NoteTitle: string;
    NoteSubmitDate: string | null;
    NoteEditDate: string | null;
    NoteBody: string;
    handleNotesEditClick?: (event: React.MouseEvent<HTMLDivElement>) => void; // Add the prop
}

const notesUtil: React.FC<NoteProps> = ({
    NoteTitle,
    NoteSubmitDate,
    NoteEditDate,
    NoteBody,
    handleNotesEditClick
}) => {
    return (
        <div className='note-card' onClick={handleNotesEditClick}>
            <div className='note-title-and-date'>
                <h2>{NoteTitle}</h2>
                <div className='note-dates'>
                    <div className='date-and-icon'>
                        <TbCalendarPlus  />
                        <p> {NoteSubmitDate}</p>
                    </div>

                    <div className='date-and-icon'>
                        <div> {NoteEditDate? <MdOutlineEditCalendar /> : ""} </div>
                        <p> {NoteEditDate}</p>
                    </div>

                </div>
            </div>

            <p>{NoteBody}</p>
        </div>
    )
}

export default notesUtil