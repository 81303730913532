import React from 'react'
// import {globalFileHolder} from './clientDashboardMain'

// Assets
import detectorCardPicture from '../img/detector/2025Detector4.jpg';
import { FaPlusCircle } from "react-icons/fa";
// import { MdOutlineSettingsInputAntenna } from "react-icons/md";
import './clientDashboardMain.css';

interface EditFormData {
    ip: string
    last_active: string
    mac: string
    online: boolean
    status: {
        fw_version: any
        gps_fix: boolean
        gps_lat: string
        gps_long: string
        linux: boolean
        memory: number
        site_name: string
        test_mode: boolean
        thr: any
        up_since: string
    }
}

// Interface for reading image
interface DetectorPhoto {
    DETECTOR_SITE_NAME: string;
    DETECTOR_IMG: any;
}


interface ClientDashboardMainEditableFormProps {
    // Editing: DetectorEditing;
    editDetecFormData: EditFormData;
    editIMGFormData: DetectorPhoto;
    handleEditDetectorFormChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCancelEdit: () => void;
    handleEditDetectorFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const ClientDashboardMainEditableForm: React.FC<ClientDashboardMainEditableFormProps> = ({
    // Editing,
    editDetecFormData,
    editIMGFormData,
    handleEditDetectorFormChange,
    handleCancelEdit,
    handleEditDetectorFormSubmit,
}) => {

    return (

        <div className='edit-pilot-info-with-picture-wrapper' > {/* ref={formWrapperRef} */}
            <div className='left-edit-pilot-info-wrapper'>
                {/* <img src={editIMGFormData.DETECTOR_IMG || detectorCardPicture} alt="" className="detec-editable-img" /> */}

                <div className='myImage2' style={{ backgroundImage: `url(${editIMGFormData.DETECTOR_IMG || detectorCardPicture})` }}>
                    <div className={editIMGFormData.DETECTOR_IMG ? "" : "myImage-overlay"}>
                        {!editIMGFormData.DETECTOR_IMG &&
                            <div className='show-plus'>
                                <FaPlusCircle className='plus-circle' />
                            </div>
                        }
                    </div>
                </div>

                <h1>{editDetecFormData.status.site_name === "" ? editDetecFormData.mac : editDetecFormData.status.site_name}</h1>
                <p>Status: {editDetecFormData.online? "🟢 Online" : "🔴 Offline"}</p>
                <p>Location: {parseFloat(editDetecFormData.status.gps_lat).toFixed(2)} | {parseFloat(editDetecFormData.status.gps_long).toFixed(2)}</p>

                <div className='editable-buttons'>
                    <button type="submit" form="pilotForm2" >Save</button>
                    <button type='button' onClick={handleCancelEdit} >Cancel</button>
                </div>
            </div>

            <div className='right-edit-pilot-info-wrapper'>
                <h1>Detector Settings</h1>
                <form id="pilotForm2" className='input-grid' onSubmit={handleEditDetectorFormSubmit} >
                    <label> Detector Name:</label>
                    <input className='my-input' type="text" name='site_name' placeholder='Name' onChange={handleEditDetectorFormChange} value={editDetecFormData.status.site_name} />
                    <label>Image:</label>
                    <input type="file" name='DETECTOR_IMG' accept='image/*' onChange={handleEditDetectorFormChange} />
                </form>
                {/* <MdOutlineSettingsInputAntenna className='detec-icon'/> */}
            </div>
        </div>
    )
}

export default ClientDashboardMainEditableForm