import { Link } from 'react-router-dom';
import { allRoutes } from '../routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  return (
    <footer className="bg-black bg-opacity-70 relative bottom-0 left-0 right-0">
      <div className="mx-auto max-w-7xl overflow-hidden py-8 md:py-12 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center space-x-4 mb-8">
          <a
            href="https://www.linkedin.com/company/fireneuralnetwork/"
            className="text-gray-400 hover:text-white"
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
          <a
            href="https://twitter.com/FireNeuralNet"
            className="text-gray-400 hover:text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <a
            href="https://www.youtube.com/@FireNeuralNet"
            className="text-gray-400 hover:text-white"
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
        </div>
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {allRoutes.map((route) => (
            <div key={route.name} className="px-5 py-2">
              <Link
                to={route.to}
                className="text-base text-gray-400 hover:text-white"
              >
                {route.name}
              </Link>
            </div>
          ))}
        </nav>
        <p className="mt-8 text-center text-base text-gray-400">
          ® {new Date().getFullYear()} FNN™. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
