import '../css/sponsors.css';
import ParticleBackground from '../components/particlesbackground';

export default function Sponsors(props: any) {
  //  using svg as logos instead of png to load the page faster
  // default is needed since as svg bc esModule object for a loader which is likely file-loader or url-loader enabled
  const usda = require('../img/sponsors/usda-logo.svg').default;
  const nsf = require('../img/sponsors/nsf-logo.svg').default;
  const corps = require('../img/sponsors/nasa_bw_logo.png');

  const idea = require('../img/sponsors/idea.svg').default;
  const world = require('../img/sponsors/world.svg').default;
  const checked = require('../img/sponsors/checked.svg').default;
  const expand = require('../img/sponsors/expand.svg').default;

  const particlesInitBackground = props.particlesInitBackground;

  return (
    <div className="relative">
      <ParticleBackground
        particlesInitBackground={particlesInitBackground}
        id="bottomParticles"
        number={100}
      />
      <div className="sponsors">
        <div className="grid-flow">
          <div className="grid-flow-first">
            <img src={nsf} alt="NSF logo" />
          </div>
          <div className="grid-flow-second">
            <img src={usda} alt="USDA logo" />
          </div>
          <div className="grid-flow-third">
            <img src={corps} alt="CORPS logo" />
          </div>
        </div>

        <div className="grid-flow-icons">
          <div className="icon-topic">
            <h4> Innovation </h4>
            <img className="icon" src={idea} alt="Idea icon" />
          </div>
          <div className="icon-topic">
            <h4> Impact </h4>
            <img className="icon" src={world} alt="World icon" />
          </div>
          <div className="icon-topic">
            <h4> Market Pull </h4>
            <img className="icon" src={checked} alt="Checked icon" />
          </div>
          <div className="icon-topic">
            <h4> Scale </h4>
            <img className="icon" src={expand} alt="Expand icon" />
          </div>
        </div>
        <p>
          FNN™ continuously pushes its technological boundaries. This ceaseless
          drive allowed FNN™ to be funded by the National Science Foundation and
          the National Institute of Food and Agriculture.
        </p>
      </div>
    </div>
  );
}
