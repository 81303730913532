import React, { useState, useEffect } from 'react';
import './App.css';
import ReportView from './pages/ReportView';

import MapView from './pages/MapView';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../Auth';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner'; // Import the loading spinner

// import fakeDetectorsBackend from './pages/fakeDetectorsForBackend.txt'
// console.log(fakeDetectorsBackend); // Should log the file content as a string

// Define the structure of the object in the detector data
export interface DetectorObject {
  mac: string;
  ip: string;
  last_active: string;
  online: boolean;
  status: {
    site_name: string;
    gps_fix: boolean;
    gps_lat: number;
    gps_long: number;
    up_since: string;
    memory: string;
    fw_version: string;
    test_mode: boolean;
    thr: string;
    linux: boolean;
    sim_card_no?:string;
  };
}
const Backend: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [detectorData, setDetectorData] = useState<DetectorObject[] | null>([]);
  const [reportGenAt, setReportGenAt] = useState<string | null>(null);
  const [detectorDataBR, setDetectorDataBR] = useState<DetectorObject[] | null>(
    []
  );
  const [reportGenAtBR, setReportGenAtBR] = useState<string | null>(null);
  const [detectorDataAll, setDetectorDataAll] = useState<DetectorObject[] | null>([]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/apidet/detector/monitoring', {
          credentials: 'same-origin',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        let alljson: any = {};
        let alljson2: any = {};

        // Check if dataUSA contains an error
        if (data.dataUSA && !data.dataUSA.error) {
          // If the raw response is a string, parse it to JSON
          const cleanedString = data.dataUSA.replace(/\\n/g, '').trim(); // Clean up the string
          const jsondata = JSON.parse(cleanedString);
          alljson = jsondata;

          // console.log(jsondata)

          // Remove the generated_at key if it exists
          if (jsondata.generated_at) {
            setReportGenAt(jsondata.generated_at);
            delete jsondata.generated_at; // Remove the key from the object
          }
          setDetectorData(jsondata);
        }

        // Same for BR Data
        // Check if dataUSA contains an error
        if (data.dataBR && !data.dataBR.error) {
          // If the raw response is a string, parse it to JSON
          const cleanedStringBR = data.dataBR.replace(/\\n/g, '').trim(); // Clean up the string
          const jsondataBR = JSON.parse(cleanedStringBR);
          alljson2 = jsondataBR;

          // Remove the generated_at key if it exists
          if (jsondataBR.generated_at) {
            setReportGenAtBR(jsondataBR.generated_at);
            delete jsondataBR.generated_at; // Remove the key from the object
          }
          setDetectorDataBR(jsondataBR);
        }

        setDetectorDataAll({ ...alljson, ...alljson2 });

        if (Object.keys(alljson).length || Object.keys(alljson2).length) {
          setIsLoading(false);
        }
        
      } catch (error) {
        //USA
        setDetectorData([]);
        setReportGenAt(null);
        //BR
        setDetectorDataBR([]);
        setReportGenAtBR(null);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    
    // Fetch data every 5 seconds
    const interval = setInterval(fetchData, 5000); // temp

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);


  const [preferredColumnsOrder, setPreferredColumnsOrder] = useState([]);

    const [selectedView, setSelectedView] = useState(() => {
      return localStorage.getItem('selectedView') || 'report';
  });

  // Update localStorage whenever selectedView changes
  useEffect(() => {
      localStorage.setItem('selectedView', selectedView);
  }, [selectedView]);


  const handleViewChange = (view: React.SetStateAction<string>) => {
    setSelectedView(view);
  };
  const FNNlogo = require('../img/FNNLogoWithWhiteFont-07.png');

  const navigate = useNavigate();

  const { username, first_name} = useAuth();

  const handleAuthRedirection = () => {
    navigate('/login'); // Replace with your desired route
  };

  const [expandedRow, setExpandedRow] = useState(null); // Ref to store the expanded row

  // useEffect(() => {
  //   console.log("MapView received detectorData:", detectorDataAll);

  //   if (detectorDataAll ) {
  //     console.log("printing the length: ", Object.keys(detectorDataAll).length)
  //   }

  // }, [detectorDataAll]);

  return (
    <div className="AppBackend backend">
      <header className="flex justify-between px-5 header">
        <a href="/">
          <img src={FNNlogo} className="App-logo" alt="FNN logo" />
        </a>
        <div className="display-flex">
          <button
            className={`bnt-header ${selectedView === 'map' ? 'active' : ''}`}
            onClick={() => handleViewChange('map')}
          >
            Map
          </button>
          <button
            className={`bnt-header ${
              selectedView === 'report' ? 'active' : ''
            }`}
            onClick={() => handleViewChange('report')}
          >
            Report
          </button>
        </div>
        <div
          onClick={handleAuthRedirection}
          className="responsive-margin flex gap-0 flex-col items-center text-base text-gray-400 hover:text-white cursor-pointer"
        >
          <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '25px', height: '25px' }}
          >
            <g id="SVGRepo_iconCarrier">
              <path
                d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z"
                fill="#28aae1"
              />
              <path
                d="M14 12C14 10.3431 12.6569 9 11 9H5C3.34315 9 2 10.3431 2 12V15H14V12Z"
                fill="#28aae1"
              />
            </g>
          </svg>
          {first_name !== "" ? first_name : username.split("@")[0]}
        </div>
      </header>


{selectedView === "map" && (
    isLoading ? (
        <LoadingSpinner />
    ) : (
      <div style={{ width: "100%", height: "100%" }}>
        {detectorDataAll && Object.keys(detectorDataAll).length === 0? 
          (<h2 style={{color: "#616060", position: 'absolute', top: '30%', left: "30%", right: "30%"}}>No available detectors to be reported.</h2>) 
          : (
            <MapView detectorData={detectorDataAll || []} />
          )}
          </div>
    )
)}

{selectedView === "report" && (
    isLoading ? (
        <LoadingSpinner />
    ) : (
        <div className={'body'}>
            {detectorData && Object.keys(detectorData).length === 0 ? 
                (<h2 style={{color: "#616060"}}>USA Report not available</h2>) : (
                <ReportView
                    detectorData={detectorData}
                    reportGenAt={reportGenAt}
                    preferredColumnsOrder={preferredColumnsOrder}
                    expandedRow={expandedRow}
                    setExpandedRow={setExpandedRow}
                />
            )}

            {detectorDataBR && Object.keys(detectorDataBR).length === 0 ? 
                (<h2 style={{color: "#616060"}}>BR Report not available</h2>) : (
                <ReportView
                    detectorData={detectorDataBR}
                    reportGenAt={reportGenAtBR}
                    preferredColumnsOrder={preferredColumnsOrder}
                    expandedRow={expandedRow}
                    setExpandedRow={setExpandedRow}
                />
            )}
        </div>
    )
)}

    </div>
  );
};

export default Backend;