import React, { useEffect, useState, useCallback } from 'react'
import './clientDashboardDocuments.css';
import ClientNavbar from '../clientNavbar/clientNavbar';
import DocumentUtil from './DocumentUtil';

// Icon imports
import { MdMenuBook } from "react-icons/md";
import { IoPlayCircle } from "react-icons/io5";
import { FaTools } from "react-icons/fa";
import { TbMessageCircleQuestion } from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

// FAQ Assets
import faqPerson from "../client-dashboard-assets/person.png"

// Particles Background Imports
import ParticleBackground from '../../components/particlesbackgroundForScrollable';
import { loadFull } from 'tsparticles';
import { loadPolygonMaskPlugin } from 'tsparticles-plugin-polygon-mask';
import { ZIndex, type Engine } from 'tsparticles-engine';

// Document Interface
interface myDocument {
    DOCUMENT_ID: number;
    DOCUMENT_NAME: string;
    DOCUMENT_DESCRIPTION: string;
    DOCUMENT_TYPE: string;
    FILE_OR_URL: string;
    DOCUMENT_FILE: string | undefined;
    DOCUMENT_URL: string | undefined;
}

// FAQ Interface
interface myFAQItem {
    FAQ_ID: number;
    FAQ_QUESTION: string;
    FAQ_ANSWER: string;
}


const clientDashboardDocuments = () => {

    // Preprocess galleryData once into a Map
    const [documentsDB, setDocumentsDB] = useState<myDocument[]>([]);
    const [faqData, setFaqData] = useState<myFAQItem[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);

    function determineDocumentType(docuType: string) {
        if (docuType === "MANUAL") {
            return <MdMenuBook className='doc-card-icon' />
        }
        else if (docuType === "VIDEO") {
            return <IoPlayCircle className='doc-card-icon' />
        }
        else if (docuType === "MAINTENANCE") {
            return <FaTools className='doc-card-icon' />
        }

    }

    const fetchDetectorDocuments = async () => {
        try {
            const response = await fetch('/api/detectordashdocs', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'same-origin',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: myDocument[] = await response.json();
            setDocumentsDB(data); // Assign the fetched gallery data to state
            // console.log(data)

        } catch (err) {
            // Type assertion to 'Error' to access the message property
            if (err instanceof Error) {
                console.error(err.message); // Handle error
            } else {
                console.error('An unknown error occurred'); // Fallback for unknown errors
            }
        }
    };

    const fetchDetectorFAQs = async () => {
        try {
            const response = await fetch('/api/detectordashfaq', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'same-origin',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: myFAQItem[] = await response.json();
            setFaqData(data); // Assign the fetched gallery data to state
            // console.log(data)

        } catch (err) {
            // Type assertion to 'Error' to access the message property
            if (err instanceof Error) {
                console.error(err.message); // Handle error
            } else {
                console.error('An unknown error occurred'); // Fallback for unknown errors
            }
        }
    };

    useEffect(() => {
        fetchDetectorDocuments();
        fetchDetectorFAQs()
    },

        []); // Empty dependency array to run once on mount

    // Splits the documents into sub arrays of 2 so that it is formatted properly for display when adding new documents
    const subaArraysOfTwo: myDocument[][] = documentsDB.reduce<myDocument[][]>
        (
            (result, _, index, arr) => {
                if (index % 2 === 0) result.push(arr.slice(index, index + 2));
                return result;
            }, []
        );

    // FAQ
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [closingIndex, setClosingIndex] = useState<number | null>(null);

    const toggleExpand = (index: number) => {
        if (expandedIndex === index) {
            setClosingIndex(index); // Trigger fade-out animation
            setTimeout(() => {
                setExpandedIndex(null); // Hide content after animation
                setClosingIndex(null);
            }, 300); // Delay unmount to match animation duration
        } else {
            setExpandedIndex(index);
            setClosingIndex(null);
        }
    };

    // Partices Background
    const particlesInit = useCallback(async (main: Engine) => {
        await loadFull(main);
        await loadPolygonMaskPlugin(main);
    }, []);

    const [width, setWidth] = useState(window.innerWidth);
    const [heightTopParticles, setHeightTopParticles] = useState(width * 0.36);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeightTopParticles(width * 0.36);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [width]);

    return (
        <div className='document-bd'>
            <ClientNavbar />


            <ParticleBackground
                particlesInitBackground={particlesInit}
                id="topParticles"
                number={80}
                aspectRatio={width / heightTopParticles}
            />

            {subaArraysOfTwo.map((pair, idx) =>
            (
                <div key={idx} className="document-stack">
                    {pair.map((doc) => (
                        <div key={doc.DOCUMENT_ID}>
                            {doc.FILE_OR_URL === 'FILE' ? (
                                <a className='docu-link' href={doc.DOCUMENT_FILE} target="_blank" rel="noopener noreferrer">
                                    <DocumentUtil
                                        icon={determineDocumentType(doc.DOCUMENT_TYPE)}
                                        docTitle={doc.DOCUMENT_NAME}
                                        docContent={doc.DOCUMENT_DESCRIPTION}
                                    />
                                </a>
                            ) : (
                                <a className='docu-link' href={doc.DOCUMENT_URL} target="_blank" rel="noopener noreferrer">
                                    <DocumentUtil
                                        icon={determineDocumentType(doc.DOCUMENT_TYPE)}
                                        docTitle={doc.DOCUMENT_NAME}
                                        docContent={doc.DOCUMENT_DESCRIPTION}
                                    />
                                </a>
                            )}
                        </div>
                    ))}
                </div>
            ))}

            <div className='faq-and-image'>
                <div className='faq-wrapper'>
                    <div className='faq-title-and-icon'>
                        <TbMessageCircleQuestion className='doc-card-icon-faq-main' />
                        <h1>FAQ</h1>
                    </div>

                    {faqData.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <div className='icon-plus-question' onClick={() => toggleExpand(index)}>
                                <h1>{faq.FAQ_QUESTION}</h1>
                                {expandedIndex === index ? <IoIosArrowUp className='doc-card-icon-faq' /> : <IoIosArrowDown className='doc-card-icon-faq' />}
                            </div>

                            {(expandedIndex === index || closingIndex === index) && (
                                <div className={`expandable ${closingIndex === index ? 'fade-out' : 'fade-in'}`}>
                                    <p>{faq.FAQ_ANSWER}</p>
                                </div>
                            )}
                        </div>
                    ))}

                </div>

                <img className='' src={faqPerson} alt='' style={{ zIndex: '1' }}></img>
            </div>
        </div>
    )
}

export default clientDashboardDocuments