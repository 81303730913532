import { FeatureCollection } from "geojson";

// Define the Detector type for TypeScript
interface Detector {
    ip: string
    last_active: string
    mac: string
    online: boolean
    status: {
        fw_version: any
        gps_fix: boolean
        gps_lat: any
        gps_long: any
        linux: boolean
        memory: number
        site_name: string
        test_mode: boolean
        thr: any
        up_since: string
    }
}

interface LightningData {
    dttime_utc: string;  // Date as a string in GMT format
    idx: string;         // Unique identifier, kept as a string to match the API response
    lcc: string;         // Lightning Cluster Classification (assuming string format)
    lcc_mac: string;     // MAC address (can be empty)
    lcc_sta: string;     // Station identifier (can be empty)
    lcc_ts: string;      // Timestamp (can be empty)
    ltg_lat: number;     // Latitude, kept as string (convert to number if needed)
    ltg_lon: number;     // Longitude, kept as string (convert to number if needed)
    nsta: string;        // Number of stations detecting the event
    polarity: string;    // Polarity value, kept as string (convert to number if needed)
    stamac: string;      // Space-separated list of station MAC addresses
    type: string;        // Lightning event type
}

const fetchDetectorCoordinates = async () => {
    try {
        if (localStorage.getItem("selectedGroup")) {
            // Fetch the text file from the public folder
            const response = await fetch(`/apidet/${localStorage.getItem("selectedGroup")}/`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'same-origin',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: Detector[] = await response.json();
            return data;
        }

    } catch (error) {
        console.error("Error fetching coordinates:", error);
    }
};

const fetchNewLightningCoordinates = async () => {
    try {
        const response = await fetch(`/apidet/lightning/${localStorage.getItem("selectedGroup")}/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'same-origin',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Check if data is an array
        if (Array.isArray(data)) {
            return data as LightningData[];
        } else {
            console.warn(data.Message || data);
            return null; // or return []; if you prefer an empty array
        }

    } catch (error) {
        console.error("Error fetching coordinates:", error);
        return null;
    }
};

async function createRanges() {
    const strikesPerRange: Map<string, number> = new Map();
    const rangeSet: Set<string> = new Set(); // Fast O(1) lookup
    const lightningCoordinates2 = await fetchNewLightningCoordinates();

    if (lightningCoordinates2) {
        for (const coord of lightningCoordinates2) {
            // Creating the minimum lat and long for the range by getting only the first decimal *WITHOUT* rounding
            const baseLat = Math.trunc(coord.ltg_lon * 10) / 10;
            const baseLon = Math.trunc(coord.ltg_lat * 10) / 10;

            // console.log("new lat", baseLat, "newLong", baseLon)

            // Creating the maximum lat and long by appending 0.99999 at the end
            const maxLat = baseLat % 1 === 0 ? parseFloat(baseLat.toString() + '.09999') : parseFloat(baseLat.toString() + '9999');
            const maxLon = baseLon % 1 === 0 ? parseFloat(baseLon.toString() + '.09999') : parseFloat(baseLon.toString() + '9999');

            // Creating the range with the aforementioned values
            const range: Array<[number, number]> = [
                [baseLat, baseLon],
                [baseLat, maxLon],
                [maxLat, maxLon],
                [maxLat, baseLon],
            ];

            const rangeKey = JSON.stringify(range);

            // Update strike count
            const currentCount = (strikesPerRange.get(rangeKey) || 0) + 1;
            strikesPerRange.set(rangeKey, currentCount);

            // Use Set for O(1) lookup
            rangeSet.add(rangeKey);
        }
    }
    return strikesPerRange;
}

// async function removeIrrelevantRangesByOccurance() {
//     const strikesPerRange = await createRanges();

//     // Count how many times each strike count appears
//     const strikeCountFrequency: Map<number, number> = new Map();
//     let IrrelevantStrikeCount = 0;

//     for (const count of Array.from(strikesPerRange.values())) {
//         strikeCountFrequency.set(count, (strikeCountFrequency.get(count) || 0) + 1);
//     }

//     // Remove ranges with a strike count of less than the irrelevant amount from strikesPerRange and rangeCoords
//     const filteredStrikesPerRange: Map<string, number> = new Map();

//     // Convert the map to an array, sort it by strike count (ascending)
//     console.log(" ")
//     Array.from(strikeCountFrequency.entries())
//         .sort(([countA], [countB]) => countA - countB) // Sort by strike count (ascending)
//         .forEach(([count, frequency]) => {

//             if (count / frequency < 0.1) {
//                 //  console.log(`Strike count: ${count}, Occurrences: ${frequency}`);
//                 //  console.log("strike/Occurrences", count / frequency)
//                 IrrelevantStrikeCount = count
//             }
//         });

//     Array.from(strikesPerRange.entries()).forEach(([rangeKey, strikeCount]) => {
//         if (strikeCount > IrrelevantStrikeCount) {
//             filteredStrikesPerRange.set(rangeKey, strikeCount);
//         }
//     });

//     return filteredStrikesPerRange;
// }

export async function removeIrrelevantRangesEllipse() {

    // const timestamp = new Date();
    // const formattedTimestamp = `${timestamp.getMonth() + 1}/${timestamp.getDate()}/${timestamp.getFullYear()} - ${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}:${timestamp.getSeconds().toString().padStart(2, '0')}:${timestamp.getMilliseconds().toString().padStart(3, '0')}${timestamp.getMilliseconds().toString().padStart(3, '0')}`;

    // console.log(formattedTimestamp);

    const strikesPerRange = await createRanges();
    const filteredStrikesPerRange: Map<string, number> = new Map();

    // Initialize bounding box with extreme values
    let top = -Infinity;
    let bottom = Infinity;
    let left = Infinity;
    let right = -Infinity;

    const detecCoordinates = await fetchDetectorCoordinates();

    if (detecCoordinates) {
        if (detecCoordinates[0] !== undefined) {
            for (const detecCoord of detecCoordinates) {
                const { gps_lat, gps_long } = detecCoord.status;

                if (
                    gps_lat !== null &&
                    gps_long !== null &&
                    Math.abs(gps_lat) > 1e-6 &&
                    Math.abs(gps_long) > 1e-6
                ) {
                    // Update bounding box
                    if (gps_lat > top) top = Number(gps_lat);
                    if (gps_lat < bottom) bottom = Number(gps_lat);
                    if (gps_long < left) left = Number(gps_long);
                    if (gps_long > right) right = Number(gps_long);
                }
            }

            // console.log("top", top)
            // console.log("bottom", bottom)
            // console.log("left", left)
            // console.log("right", right)
        }

        // Calculate the dimensions
        const length = Math.abs(top - bottom); // Difference in latitude
        const width = Math.abs(right - left);  // Difference in longitude

        let paddingLat = 0
        let paddingLong = 0

        // In case length is too small then add 0.5 rather than calculate padding based on % of its size
        if (length < 1) {
            paddingLat = 0.5 + length;
        }
        else {
            paddingLat = 0.5 * length;
        }

        // In case width is too small then add 0.5 rather than calculate padding based on % of its size
        if (width < 1) {
            paddingLong = 0.5 + width;
        }
        else {
            paddingLong = 0.5 * width;
        }


        // Compute the new bounds with padding
        const bounds = {
            top: top + paddingLat,      // Extend north
            bottom: bottom - paddingLat, // Extend south
            left: left - paddingLong,    // Extend west
            right: right + paddingLong   // Extend east
        };

        // Define the bounding rectangle coordinates
        const topLeft: [number, number] = [bounds.left, bounds.top];
        const bottomRight: [number, number] = [bounds.right, bounds.bottom];

        // Calculate the center of the rectangle
        const centerX = (topLeft[0] + bottomRight[0]) / 2;
        const centerY = (topLeft[1] + bottomRight[1]) / 2;

        // Calculate the width and height of the rectangle (semi-major and semi-minor axes)
        const semiMajorAxis = Math.abs(bottomRight[0] - topLeft[0]) / 2;  // Horizontal distance
        const semiMinorAxis = Math.abs(bottomRight[1] - topLeft[1]) / 2;  // Vertical distance

        // Function to check if a point is inside the ellipse
        const isWithinEllipse = (lat: number, long: number, centerX: number, centerY: number, semiMajorAxis: number, semiMinorAxis: number) => {
            const xTerm = Math.pow((long - centerX) / semiMajorAxis, 2);
            const yTerm = Math.pow((lat - centerY) / semiMinorAxis, 2);
            return (xTerm + yTerm) <= 1; // Check if point lies inside or on the ellipse
        };

        // Convert the Map to an array of entries
        const strikesArray = Array.from(strikesPerRange.entries());

        // Iterate over the array of entries
        for (const [range, strikeCount] of strikesArray) {
            // Parse the stringified range back to an array
            const parsedRange = JSON.parse(range);

            // Check if all points of the range are within the ellipse
            const isInEllipse = parsedRange.every(([long, lat]: [number, number]) => isWithinEllipse(lat, long, centerX, centerY, semiMajorAxis, semiMinorAxis));

            if (isInEllipse) {
                // Add the valid range to the filtered map
                filteredStrikesPerRange.set(range, strikeCount);
            }
        }
    }

    // console.log('End timestamp:', formattedTimestamp);

    // Return the filtered map
    return filteredStrikesPerRange;

}

// Async function to generate statesData
async function generateLightningData() {
    const filteredStrikesPerRange = await removeIrrelevantRangesEllipse();

    const statesData: FeatureCollection = {
        type: "FeatureCollection",
        features: Array.from(filteredStrikesPerRange.keys()).map((key: string, index: number) => {
            const coords: Array<[number, number]> = JSON.parse(key); // Convert string back to array
            const strikeCount = filteredStrikesPerRange.get(key) || 0; // Get strike count

            return {
                type: "Feature",
                id: (index + 1).toString().padStart(2, "0"), // ID starts at 1 and is zero-padded
                properties: {
                    name: `Strike Zone ${index + 1}`,
                    density: strikeCount, // Use actual count from strikesPerRange
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [coords], // Wrap in an array as GeoJSON requires a nested array for Polygon
                }
            };
        })
    };

    return statesData;
}

export default generateLightningData;