import React from 'react';

interface NotesFormUtilProps {
    note: {
        NOTE_TITLE: string;
        NOTE_SUBMIT_DATE: string;
        NOTE_EDIT_DATE: string | null;
        NOTE_BODY: string;
    };
    handleCancelNotesButton: () => void;
    handleNotesDeleteClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // Add the prop
    handleEditNotesChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleEditNotesSubmit: (event: React.FormEvent<HTMLFormElement>) => void;

    //   handleSubmit: (event: React.FormEvent) => void;
}

const NotesFormUtil: React.FC<NotesFormUtilProps> = ({ note, handleCancelNotesButton, handleEditNotesChange, handleEditNotesSubmit, handleNotesDeleteClick }) => {

    // console.log(note)

    // Add a day due to TSX and JSX timezone issues
    const formatDateString = (date: string): string => {
        const dateObj = new Date(date);
        const year = dateObj.getUTCFullYear();
        const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');  // Months are 0-indexed
        const day = dateObj.getUTCDate().toString().padStart(2, '0'); // Get day of the month
        return `${month}/${day}/${year}`; // Format date as MM-DD/YYYY
    };

    return (
        <form id="notesForm" className='notes-grid' onSubmit={handleEditNotesSubmit}>
            <div className='label-and-input'>
                <label>Title:</label>
                <input
                    className='notes-input'
                    type="text"
                    name='NOTE_TITLE'
                    value={note.NOTE_TITLE}
                    placeholder='Enter Title'
                    onChange={handleEditNotesChange}
                /></div>

            <p> Created on: {new Date(formatDateString(note.NOTE_SUBMIT_DATE)).toLocaleDateString("en-US")}</p>
            
            {note.NOTE_EDIT_DATE === null ?
                ""
                : (
                    <p>Edited on: {new Date(formatDateString(note.NOTE_EDIT_DATE)).toLocaleDateString("en-US")}</p>
                )}


            {/* <input
                className='notes-input'
                type="date"
                name="NOTE_EDIT_DATE" // ✅ Matches the state key
                value={note.NOTE_EDIT_DATE} // ✅ Always pull from state
                // readOnly
                placeholder='Enter Date'
                onChange={handleEditNotesChange}
            /> */}

            <div className='label-and-input'>
                <label>Note:</label>
                <textarea
                    className='notes-input'
                    name="NOTE_BODY"
                    rows={6}
                    value={note.NOTE_BODY}
                    placeholder='Enter Note Body'
                    onChange={handleEditNotesChange}
                ></textarea>
            </div>


            <div className='notes-buttons'>
                <button type="submit" form="notesForm">Save</button>
                <button type='button' onClick={handleCancelNotesButton}>Cancel</button>
                <button type='button' onClick={handleNotesDeleteClick}>Delete</button>
            </div>
        </form>
    );
};

export default NotesFormUtil;
