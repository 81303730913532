import React, { useRef, useState, useEffect, useCallback } from 'react';
import './clientDashboardMap.css';
import ClientNavbar from '../client-dashboard/clientNavbar/clientNavbar';
import LoadingSpinner from '../backend/components/LoadingSpinner'; // Import the loading spinner
import LoadingBar from '../backend/components/LoadingBar';
// import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
// import Map from '../components/map';

//Assets
import fnnPopupLogo from './client-dashboard-assets/IMG_FNNLogo_Big_WhiteBackground.png'
import greenMarker from './client-dashboard-assets/mapdetectoricon_green.png'
import offlineMarker from './client-dashboard-assets/mapsmarker_bw.png'

// Map functionality and Design imports
import GradientLegend, { interpolateColor } from "./cilent-dashboard-util/GradientLegend";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
    GeoJSON
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { Icon } from "leaflet";
import L, { LeafletEvent, GeoJSON as LeafletGeoJSON } from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
// import detectorData from './fake-detectors.json'
import generateLightningData from './lightningData';

// ------------- Popup imports -------------
import Modal from "../components/UtilComponents/Modal";
import { useAuth } from '../Auth';
// ------------- Popup imports -------------

import { HeatMapLayer, heatMapData } from './HeatMapMap'

// Define the Detector type for TypeScript
interface Detector {
    ip: string
    last_active: string
    mac: string
    online: boolean
    status: {
        fw_version: any
        gps_fix: boolean
        gps_lat: any
        gps_long: any
        linux: boolean
        memory: number
        site_name: string
        test_mode: boolean
        thr: any
        up_since: string
    }
}

export const BwIcon = new Icon({
    iconUrl: offlineMarker,
    iconSize: [50, 50]
});

export const greenIcon = new Icon({
    iconUrl: greenMarker,
    iconSize: [50, 50]
});

interface CenterMapOnDetectorProps {
    latitude: number;
    longitude: number;
    zoomLevel: number;
}

/* ---------------- Functions for determining the range colors based on the lightning count START ---------------- */

const getColorForMarker = (isOn: boolean) => {
    if (isOn === false) return BwIcon;
    return greenIcon; //green
};

function getMinMaxDensity(data: any | null) {
    if (!data) return { min: 0, max: 0 }; // Handle null case safely

    const features = (data as GeoJSON.FeatureCollection).features; // Ensure correct type
    const densities = features
        .map((feature) => feature.properties?.density) // Use optional chaining to avoid null errors
        .filter((density): density is number => density !== undefined && density !== null); // Filter out undefined/null values

    // console.log(Math.min(...densities), Math.max(...densities))

    if (densities.length === 0) return { min: 0, max: 0 }; // Handle empty array case

    return {
        min: Math.min(...densities),
        max: Math.max(...densities),
    };
}

function style(feature: any, min: number, max: number) {
    const fillColor = interpolateColor(feature.properties.density, min, max).color;

    return {
        fillColor: 'transparent',
        weight: 2, // Thin border
        opacity: 0.8, // Slight opacity on border
        color: 'transparent', // Transparent border color
        dashArray: '3', // Optional dashed effect
        fillOpacity: 0.6, // Slight opacity for fill
        // Create a subtle "fuzzy" border by applying a shadow
        // with a slight blur to make the transitions smoother
        shadowBlur: 4, // Adjust this for smoothness (bigger = smoother)
        shadowColor: fillColor, // Same color as fill
        shadowOpacity: 0.3, // Make the shadow subtle
    };
}

// function style(feature: any, min: number, max: number) {
//     return {
//         fillColor: interpolateColor(feature.properties.density, min, max).color,
//         weight: 2,
//         opacity: 1,
//         color: 'transparent',
//         dashArray: '3',
//         fillOpacity: 0.4
//     };
// }

const SearchControl: React.FC = () => {
    const map = useMap();

    useEffect(() => {
        const provider = new OpenStreetMapProvider();

        const searchControl = new (GeoSearchControl as any)({ // Fix: Explicitly cast to `any`
            provider,
            style: 'bar',
            showMarker: false,
            showPopup: true,
            popupFormat: ({ query, result }: { query: string; result: { label: string } }) =>
                `You searched for: ${query}<br>Result: ${result.label}`,
            searchLabel: 'Search Address or Zip',
        });

        map.addControl(searchControl);

        return () => {
            map.removeControl(searchControl);
        }; // Fix: Cleanup function now correctly returns void
    }, [map]);

    return null;
};

// const CenterMapOnDetector: React.FC<CenterMapOnDetectorProps> = ({ latitude, longitude, zoomLevel }) => {
//     const map = useMap();

//     useEffect(() => {
//         if (latitude && longitude) {
//             map.flyTo([latitude, longitude], zoomLevel, {
//                 duration: 1.5,
//             });
//         }
//     }, [latitude, longitude, zoomLevel, map]);

//     return null;
// };

const CenterMapOnDetector2: React.FC<CenterMapOnDetectorProps> = ({ latitude, longitude, zoomLevel }) => {
    const map = useMap();

    useEffect(() => {
        if (latitude && longitude) {
            const startZoom = map.getZoom();
            const startLatLng = map.getCenter();
            const startTime = performance.now();
            const duration = 500; // 1.5 seconds animation

            const animate = (timestamp: number) => {
                const elapsed = timestamp - startTime;
                const t = Math.min(elapsed / duration, 1); // Ensure t is between 0 and 1

                const newLat = startLatLng.lat + (latitude - startLatLng.lat) * t;
                const newLng = startLatLng.lng + (longitude - startLatLng.lng) * t;
                const newZoom = startZoom + (zoomLevel - startZoom) * t;

                map.setView([newLat, newLng], newZoom, { animate: false });

                if (t < 1) {
                    requestAnimationFrame(animate);
                }
            };

            requestAnimationFrame(animate);
        }
    }, [latitude, longitude, zoomLevel, map]);

    return null;
};

const FetchingLightningData = () => {
    const [statesData, setStatesData] = useState<GeoJSON.GeoJsonObject | null>(null);
    const [isGeoJsonLoaded, setIsGeoJsonLoaded] = useState(false);

    const fetchData = async () => {
        const data = await generateLightningData(); // Your function to get lightning data
        setStatesData(data);
        setIsGeoJsonLoaded(true); // Set it to true once GeoJSON is loaded
    };

    useEffect(() => {
        fetchData(); // Initial data fetch when the component mounts

        // Reload the data every 5 seconds
        const intervalId = setInterval(fetchData, 10000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return { statesData, isGeoJsonLoaded };
};


/* ---------------- Functions for determining the range colors based on the lightning count END ---------------- */

const ClientDashboardMapNew = () => {

    // Refresh in case group changes
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === "selectedGroup") {
                // If the selectedGroup changes in localStorage, refresh the page
                window.location.reload();
            }
        };

        // Listen for changes in localStorage
        window.addEventListener("storage", handleStorageChange);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const [heatmapData, setHeatmapData] = useState<any>([]);
    const [min2, setMin2] = useState<any>(null);
    const [max2, setMax2] = useState<any>(null);

    // Function to load data
    const loadData = async () => {
        const data = await heatMapData();
        setHeatmapData(data);
    };

    useEffect(() => {
        // Load data initially
        loadData();

        // Set interval to load data every 5 seconds
        const intervalId = setInterval(() => {
            loadData();
        }, 10000); // 5000ms = 5 seconds

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures this runs only once on mount

    const handleExtremaChange = (newMin: number, newMax: number) => {
        setMin2(newMin);
        setMax2(newMax);
    };


    // --------------------------------------------- Popup START ---------------------------------------------
    const { isAuthenticated, getDashboardPermissions } = useAuth();
    const [clientPermissionGroups, setClientPermissionGroups] = useState<{ [key: string]: any }>({});

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [groups, setGroups] = useState<string[]>([]);
    const [chosenGroupCallback, setChosenGroupCallback] = useState<((groupName: string) => void) | null>(null);
    const [savedGroup, setSavedGroup] = useState<string | null>(localStorage.getItem("selectedGroup"));

    const tempName = Object.keys(clientPermissionGroups).filter(groupName => clientPermissionGroups[groupName]);

    useEffect(() => {
        const fetchClientPermissions = async () => {
            const permissions2 = await getDashboardPermissions(); // Await the promise
            setClientPermissionGroups(permissions2 || {});

            // Return a promise that resolves when a group is selected
            return new Promise((resolve) => {
                setChosenGroupCallback(() => (selectedGroup: string) => {
                    setModalOpen(false); // Close the modal
                    localStorage.setItem("selectedGroup", selectedGroup); // Save to local storage
                    resolve(selectedGroup); // Resolve the promise with the selected group
                });
            });
        };

        if (isAuthenticated) {
            fetchClientPermissions();
        }
    }, [isAuthenticated, getDashboardPermissions]);

    useEffect(() => {
        if (!savedGroup && clientPermissionGroups && Object.keys(clientPermissionGroups).length > 1) {
            // Extract the group names with a true value
            const groupNames = Object.keys(clientPermissionGroups).filter(groupName => clientPermissionGroups[groupName]);

            // Set the popup to open and assign the name of the buttons based on the names of the read groups
            setGroups(groupNames);
            setModalOpen(true);
        }

        if (clientPermissionGroups && Object.keys(clientPermissionGroups).length === 1) {
            console.log("here")
            // Extract the group name with a true value
            const firstGroup = Object.entries(clientPermissionGroups)[0]; // Get the first key-value pair

            // Check if the user has access to that specified table
            if (firstGroup[1] === true) {
                handleFunction(firstGroup[0]);
            } else {
                console.warn("This user does not have permission to access this table");
            }
        }
    }, [clientPermissionGroups, savedGroup]); // Runs after `clientPermissionGroups` updates

    // Check localStorage for selected group whenever savedGroup or groups change
    useEffect(() => {
        const storedGroup = localStorage.getItem("selectedGroup");

        // Ensure that groups is populated before performing the check
        if (groups.length === 0) {
            return; // Don't proceed until groups are populated
        }

        if (storedGroup && !groups.includes(storedGroup)) {
            console.warn(`The selected group "${storedGroup}" is not valid.`);
            localStorage.setItem("selectedGroup", groups[0]); // Save to local storage
            return; // Exit early after redirect
        }

        // If the stored group is valid, set it to state
        setSavedGroup(storedGroup);
    }, [groups]); // Runs when `groups` changes


    const handleFunction = (selectedGroup: string) => {
        if (chosenGroupCallback) {
            chosenGroupCallback(selectedGroup); // Trigger the callback with the selected group
            localStorage.setItem("selectedGroup", selectedGroup);

            if (clientPermissionGroups && Object.keys(clientPermissionGroups).length > 1) {
                window.location.reload();
            }
            setSavedGroup(selectedGroup); // Update the state with the selected group
        }
    };

    // --------------------------------------------- Popup END ---------------------------------------------



    const { statesData, isGeoJsonLoaded } = FetchingLightningData(); // Fetching data here
    const { min, max } = getMinMaxDensity(statesData);

    /* ------------------------ FUNCTIONS TO DETERMINE HOVER ANIMATION ON LIGHTNING COUNT ------------------------ */
    const geojsonRef = useRef<L.GeoJSON | null>(null); // Create a reference for the GeoJSON layer
    const [hoveredFeature, setHoveredFeature] = useState<{ name: string; density: number } | null>(null);


    function highlightFeature(e: LeafletEvent) {
        const layer = e.target as L.Path; // Cast to Leaflet Path for setStyle

        layer.setStyle({
            weight: 5,
            color: "#666",
            dashArray: "",
            fillOpacity: 0.5,
        });

        layer.bringToFront();
    }

    // Reset highlight on mouseout
    function resetHighlight(e: LeafletEvent) {
        if (geojsonRef.current) {
            geojsonRef.current.resetStyle(e.target);
        }
    }

    useEffect(() => {
        if (geojsonRef.current && statesData) {
            // Clear the previous GeoJSON layer
            geojsonRef.current.clearLayers();

            // Add the new GeoJSON layer with the updated data
            geojsonRef.current.addData(statesData);
        }
    }, [statesData]); // This effect will run every time `statesData` changes

    // Zoom to feature on click with some padding
    function zoomToFeature(e: LeafletEvent) {
        const map = e.target._map;
        if (map) {
            map.fitBounds(e.target.getBounds(), {
                padding: [20, 20],
                maxZoom: map.getZoom() + 2, // Adjust this to limit zoom-in
            });
        }
    }

    // Function to handle each GeoJSON feature
    function onEachFeature(feature: GeoJSON.Feature, layer: L.Layer) {
        layer.on({
            mouseover: (e: LeafletEvent) => {
                const layer = e.target as L.Path;
                highlightFeature(e);

                if (feature.properties) {
                    setHoveredFeature({
                        name: feature.properties.name,
                        density: feature.properties.density
                    });
                }
            },
            mouseout: (e: LeafletEvent) => {
                resetHighlight(e);
                setHoveredFeature(null); // Clear the hovered data when the mouse leaves
            },

            click: zoomToFeature,
        });
    }

    const computedStyle = useCallback((feature: any) => style(feature, min, max), [min, max]);


    /* ------------------------ FUNCTIONS TO DETERMINE HOVER ANIMATION ON LIGHTNING COUNT ------------------------ */

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 7000); // 7 seconds

        return () => clearTimeout(timer); // Cleanup timer on unmount
    }, []);

    const [loadingLightning, setLoadingLightning] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingLightning(false);
        }, 10000); // 7 seconds

        return () => clearTimeout(timer); // Cleanup timer on unmount
    }, []);

    // --------------------------------------------- Getting MAC from new API START ---------------------------------------------
    const [macsFromAPI, setMacsFromAPI] = useState<Detector[]>([]);
    const [detectorJson, setDetectorJson] = useState<Detector[]>([]); // Initialize with empty array or macsFromAPI
    const [isMapReady, setIsMapReady] = useState(false); // State to track if the map can be rendered


    const fetchMACsFromAPI = async (selectedGroup: string | null) => {
        try {
            if (!selectedGroup) return; // Prevent fetch if no group is selected

            const response = await fetch(`/apidet/${selectedGroup}/`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'same-origin',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: Detector[] = await response.json();
            setMacsFromAPI(data); // Assign the fetched data to state
        } catch (err) {
            if (err instanceof Error) {
                console.error(err.message);
            } else {
                console.error('An unknown error occurred');
            }
        }
    };

    // Fetch on initial load and then every 5 seconds
    useEffect(() => {
        if (savedGroup) {
            fetchMACsFromAPI(savedGroup);

            const interval = setInterval(() => {
                fetchMACsFromAPI(savedGroup);
            }, 5000); // 5000 ms = 5 seconds

            // Clear interval on component unmount or when group changes
            return () => clearInterval(interval);
        }
    }, [savedGroup]); // Runs when savedGroup changes

    // Whenever macsFromAPI changes, update detectorJson
    useEffect(() => {
        setDetectorJson(macsFromAPI); // Sync macsFromAPI with detectorJson
    }, [macsFromAPI]);

    // Whenever macsFromAPI changes, update detectorJson
    useEffect(() => {
        setDetectorJson(macsFromAPI); // Sync macsFromAPI with detectorJson

        // Check if a valid detector with coordinates exists
        const validDetector = Object.values(macsFromAPI).find(
            (detector) => detector.status?.gps_lat && detector.status?.gps_long
        );

        // If a valid detector is found, set isMapReady to true
        if (validDetector) {
            setIsMapReady(true);
        }

    }, [macsFromAPI]);

    const validDetector = Object.values(detectorJson).find(
        (detector) => detector.status?.gps_lat && detector.status?.gps_long
    );

    // Provide default coordinates if validDetector is undefined
    const center: [number, number] = validDetector
        ? [Number(validDetector.status.gps_lat), Number(validDetector.status.gps_long)]
        : [37.7749, -122.4194]; // Example fallback (San Francisco)


    // --------------------------------------------- Getting MAC from new API END ---------------------------------------------

    // State to control visibility of the form
    const [isFormVisible, setFormVisible] = useState(true);
    const [isArrowVisible, setArrowVisible] = useState(false);
    const [isUserToggled, setUserToggled] = useState(false); // Track manual toggling of the form

    // Create a reference for the form wrapper for making it visible/hidden based on the button clicked
    const formWrapperRef = useRef(null);

    // Function for toggling the dashboard menu when on mobile
    const handleToggleForm = () => {
        setFormVisible((prev) => !prev);
        setUserToggled((prev) => !prev);
    };

    // Making the arrow to use the menu pop up when the user is on mobile
    const handle_window_resize = (window: number) => {
        if (window >= 700) {
            setFormVisible(true);
            // setUserToggled(false);
        }
        if (window < 700) {
            if (!isUserToggled) {
                setFormVisible(false);
            }
        }
    }

    // Function to check if the user is in mobile view in order to display the arrow button or not
    useEffect(() => {
        const updateGridStyle = () => {
            // Adjust the bottom position based on visibility
            const arrowBottomPosition = isFormVisible ? '97%' : '-2%';
            const zIndex = isFormVisible ? '2' : '0';

            document.documentElement.style.setProperty('--arrow-bottom-position', arrowBottomPosition);
            document.documentElement.style.setProperty('--z-index', zIndex);

            // Handle mobile view logic
            setArrowVisible(window.innerWidth < 700);
            handle_window_resize(window.innerWidth);

        };

        updateGridStyle();
        window.addEventListener('resize', updateGridStyle);

        return () => window.removeEventListener('resize', updateGridStyle);
    }, [isFormVisible]);

    const markerRefs = useRef<{ [key: string]: any }>({}); // Refs for markers

    // Variable to center the zoom on the detector
    const [centerCoordinates, setCenterCoordinates] = useState<{ latitude: number; longitude: number } | null>(null);

    // const detectorArray: DetectorObject[] = Object.values(detectorData);

    // Function to open popup for a specific detector
    const openPopup = (detectorId: string) => {
        const detector = Object.values(detectorJson).find(
            (det) => det.mac === detectorId
        );

        if (detector && markerRefs.current[detectorId]) {
            const latitude = Number(detector.status.gps_lat)
            const longitude = Number(detector.status.gps_long)

            markerRefs.current[detectorId].openPopup();
            // Temporarily reset centerCoordinates to force re-render
            setCenterCoordinates(null);
            setTimeout(() => {
                setCenterCoordinates({ latitude, longitude });
            }, 0); // Small delay to allow React to reset
        }
    };

    // Separate detectors into online and offline groups
    const onlineDetectors = Object.values(detectorJson).filter((detector) => detector.online === true);
    const offlineDetectors = Object.values(detectorJson).filter((detector) => detector.online === false);

    // Search variables and funtions
    const [searchTerm, setSearchTerm] = useState<string>(""); // State to store the search term

    // Function to handle search input changes
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(e.target.value.toLowerCase()); // Update search term and convert to lowercase for case-insensitive matching
    };

    // Filter the detectors based on the search term
    const filteredOnlineDetectors = onlineDetectors.filter((detector) => {
        const siteName = detector.status?.site_name;
        const mac = detector.mac?.toLowerCase();

        if (!siteName) {
            return mac?.includes(searchTerm);
        }
        return siteName.toLowerCase().includes(searchTerm);
    });

    const filteredOfflineDetectors = offlineDetectors.filter((detector) => {
        const siteName = detector.status?.site_name;
        const mac = detector.mac?.toLowerCase();

        if (!siteName) {
            return mac?.includes(searchTerm);
        }
        return siteName.toLowerCase().includes(searchTerm);
    });


    return (
        <div style={{ width: "100%", height: "100%" }}> {/* Ensure it has a height */}

            <ClientNavbar setSavedGroup={setSavedGroup} groupCount={Object.keys(clientPermissionGroups).length} />

            {modalOpen &&
                <Modal
                    popupTitle={"Which group would you like to access?"}
                    setOpenModal={setModalOpen}
                    functionToExecute={handleFunction}
                    groupNames={groups}
                />}

            <div className="map-and-dash">
                {isMapReady ? (
                    <>
                        {hoveredFeature && (
                            <div className='lightning-info'>
                                <div className="hover-info">
                                    <p>Strikes: <br />{hoveredFeature.density}⚡</p>
                                </div>
                            </div>
                        )}

                        {(min2 >= 0 && max2 > 0) ? (
                            <div className={`lightning-info-legend ${isFormVisible ? 'hidden' : ''}`}>
                                <div className="lightning-info-legend-inner">
                                    <GradientLegend min={1} max={max2} />
                                </div>
                            </div>

                        ) : (
                            <div className='lightning-info-legend-loading'>
                                {loading ? <LoadingBar />
                                    :
                                    <div className='no-lightning-data'>
                                        <p>No⚡data in this region.</p>
                                    </div>}
                            </div>
                        )}

                        <div className="my-map">
                            <MapContainer
                                center={center}
                                zoom={9}
                                minZoom={2}  // Set the minimum zoom level
                                style={{ width: "100%", height: "100%" }} // Ensure the map takes up the full container
                            >
                                {/* Add a TileLayer to display map tiles */}
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />

                                {/* Add the heatmap layer */}
                                <HeatMapLayer data={heatmapData} onExtremaChange={handleExtremaChange} />

                                {/* Add the search control */}
                                <SearchControl />

                                {centerCoordinates && (
                                    <CenterMapOnDetector2
                                        latitude={centerCoordinates.latitude}
                                        longitude={centerCoordinates.longitude}
                                        zoomLevel={9} // Specify the desired zoom level
                                    />
                                )}

                                {/* Conditionally render GeoJSON layer when statesData is available */}
                                {isGeoJsonLoaded && statesData && (
                                    <GeoJSON
                                        data={statesData as GeoJSON.GeoJsonObject}
                                        style={computedStyle}
                                        onEachFeature={onEachFeature} // Attach event handlers
                                        ref={geojsonRef} // Assign reference to the GeoJSON layer
                                    />
                                )}

                                {Object.values(detectorJson).map((currentDetector) => {
                                    if (
                                        currentDetector &&
                                        currentDetector.status &&
                                        Object.keys(currentDetector.status).length > 0 && // Ensure status is not empty
                                        currentDetector.status.gps_lat !== null &&
                                        currentDetector.status.gps_long !== null &&
                                        Math.abs(Number(currentDetector.status.gps_lat)) > 1e-6 &&
                                        Math.abs(Number(currentDetector.status.gps_long)) > 1e-6
                                    ) {
                                        return (
                                            <Marker
                                                key={currentDetector.mac}
                                                position={[
                                                    Number(currentDetector.status.gps_lat),
                                                    Number(currentDetector.status.gps_long),
                                                ]}
                                                icon={getColorForMarker(currentDetector.online)}
                                                ref={(el) => (markerRefs.current[currentDetector.mac] = el)}
                                            >
                                                <Popup>
                                                    <img src={fnnPopupLogo} alt="" className="fnn-popup-logo" />
                                                    Detector: {currentDetector.status.site_name === "" ? currentDetector.mac : currentDetector.status.site_name} <br />
                                                    Status: {currentDetector.online === true ? "🟢 Online" : "🔴 Offline"}<br />
                                                    Latitude: {currentDetector.status.gps_lat}<br />
                                                    Longitude: {currentDetector.status.gps_long}<br />
                                                </Popup>
                                            </Marker>
                                        );
                                    }
                                    return null; // Do not render anything
                                })}

                            </MapContainer>
                        </div>

                        <div className="arrow-and-dash">
                            {isArrowVisible && (
                                <div
                                    className="circle-around-arrow"
                                    onClick={handleToggleForm}
                                >
                                    {isFormVisible ? <IoIosArrowDown className="arrow-icon" /> : <IoIosArrowUp className="arrow-icon" />}
                                </div>
                            )}

                            <div ref={formWrapperRef} className={`my-dash ${isFormVisible ? "visible" : ""}`}>
                                <h1>Status</h1>
                                <div className="input-box">
                                    <input
                                        type="text"
                                        name="detec-search"
                                        placeholder="Search Detectors"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <div className="online-offline">
                                    <h2>Online</h2>
                                    <div className="detec-group">
                                        {filteredOnlineDetectors.map((detector) => {
                                            if (
                                                detector.status.gps_lat !== null &&
                                                detector.status.gps_long !== null &&
                                                Math.abs(Number(detector.status.gps_lat)) > 1e-6 &&
                                                Math.abs(Number(detector.status.gps_long)) > 1e-6
                                            ) {
                                                return (
                                                    <p
                                                        key={detector.mac}
                                                        onClick={() => {
                                                            openPopup(detector.mac);
                                                            if (window.innerWidth < 700) {
                                                                setFormVisible((prev) => !prev);
                                                                setUserToggled((prev) => !prev);
                                                            }
                                                        }}
                                                    >
                                                        🟢 {detector.status.site_name || detector.mac}
                                                    </p>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>

                                    <h2>Offline</h2>
                                    <div className="detec-group">
                                        {filteredOfflineDetectors.map((detector) => {
                                            if (
                                                detector.status.gps_lat !== null &&
                                                detector.status.gps_long !== null &&
                                                Math.abs(Number(detector.status.gps_lat)) > 1e-6 &&
                                                Math.abs(Number(detector.status.gps_long)) > 1e-6
                                            ) {
                                                return (
                                                    <p
                                                        key={detector.mac}
                                                        onClick={() => {
                                                            openPopup(detector.mac);
                                                            if (window.innerWidth < 700) {
                                                                setFormVisible((prev) => !prev);
                                                                setUserToggled((prev) => !prev);
                                                            }
                                                        }}
                                                    >
                                                        🔴 {detector.status.site_name || detector.mac}
                                                    </p>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                ) : (
                    <div>
                        {loading ? (
                            <>
                                <div>
                                    {/* Your existing structure for loading state */}
                                    <LoadingSpinner />
                                </div>
                            </>
                        ) : (
                            <div className="empty-message">
                                <p>No detectors available in this group.</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );

}

export default ClientDashboardMapNew