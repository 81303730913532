import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// Shell of app
import App from './App';
import { AuthProvider } from './Auth';
import ErrorPage from './pages/error';

// Pages
import Home from './pages/home';
import HrlDetector from './pages/hrl-detector';
import HrlFirebird from './pages/hrl-firebird';
import HrlMapping from './pages/hrl-mapping';
import Mission from './pages/mission';
import News from './pages/news';
import Hotspot from './pages/hotspot';
import FireDetails from './pages/fire-details';
import Stories from './pages/stories';
import WelcomeFire from './pages/welcome-fire';
import Contact from './pages/contact';
import Opportunities from './pages/opportunities';
import GatorX from './pages/gatorx';
import FnnBackend from './backend/fnnbackend';
import Login from './components/login';

// Client Dashboard links
import ClientDashboard from './client-dashboard/clientDashboardMain';
import ClientDashboardMapNew from './client-dashboard/clientDashboardMapNew';
import ClientDashboardDocuments from './client-dashboard/client-dashboard-documents/clientDashboardDocuments';
import ClientEsriSelection from './client-dashboard/client-esri-selection/clientEsriSelection';
// import HeatMapMap from './client-dashboard/HeatMapMap';

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <App>
        <Home />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/login',
    element: (
      <App>
        <Login />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/hrl-detector',
    element: (
      <App>
        <HrlDetector />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '//',
    element: (
      <App>
        <HrlDetector />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/hrl-mapping',
    element: (
      <App>
        <HrlMapping />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/hrl-mapping/',
    element: (
      <App>
        <HrlMapping />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/hrl-firebird',
    element: (
      <App>
        <HrlFirebird />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/hrl-firebird/',
    element: (
      <App>
        <HrlFirebird />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/mission',
    element: (
      <App>
        <Mission />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/mission/',
    element: (
      <App>
        <Mission />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/news',
    element: (
      <App>
        <News />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/news/',
    element: (
      <App>
        <News />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/hotspot',
    element: (
      <App>
        <Hotspot />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/hotspot/',
    element: (
      <App>
        <Hotspot />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/hotspot/:id',
    element: (
      <App>
        <FireDetails />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/stories',
    element: (
      <App>
        <Stories />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/stories/',
    element: (
      <App>
        <Stories />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/contact',
    element: (
      <App>
        <Contact />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/contact/',
    element: (
      <App>
        <Contact />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/welcomefire',
    element: (
      <App>
        <WelcomeFire />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/welcomefire/',
    element: (
      <App>
        <WelcomeFire />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/opportunities',
    element: (
      <App>
        <Opportunities />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/opportunities/',
    element: (
      <App>
        <Opportunities />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/gatorX',
    element: (
      <App>
        <GatorX />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/gatorX/',
    element: (
      <App>
        <GatorX />
      </App>
    ),
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnnbackend',
    element: <>{<FnnBackend />}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnnbackend/',
    element: <>{<FnnBackend />}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnn_client_dashboard',
    element: <>{<ClientDashboard />}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnn_client_dashboard/',
    element: <>{<ClientDashboard />}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnn_client_dashboard_map',
    element: <>{<ClientDashboardMapNew />}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnn_client_dashboard_map/',
    element: <>{<ClientDashboardMapNew />}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnn_client_dashboard_documents',
    element: <>{<ClientDashboardDocuments />}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnn_client_dashboard_documents/',
    element: <>{<ClientDashboardDocuments />}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnn_client_dashboard_esri',
    element: <>{<ClientEsriSelection />}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  },
  {
    path: '/fnn_client_dashboard_esri/',
    element: <>{<ClientEsriSelection/>}</>,
    errorElement: (
      <App>
        <ErrorPage />
      </App>
    ),
  }
]);

// Load the react app into our DOM
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <AuthProvider>
      {' '}
      {/* AuthProvider in all app context */}
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
