import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LoadingSpinner from '../backend/components/LoadingSpinner'; // Import the loading spinner


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function Gallery(props: any) {
  const galleryData = props.data;
  const isLoading = props.isLoading;
  const slidesElements = galleryData.map((slide: any) => (
    <SwiperSlide key={slide.imgAlt}>
      <img
        src={slide.img}
        alt={slide.imgAlt}
        className="Carouselpics"
        style={{ maxHeight: '80vh' }}
      />
    </SwiperSlide>
  ));
  return (
    <>
      {/* Gallery Section */}
      <h1 className="overview">{props.title}</h1>
      <p>{isLoading}</p>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mx-auto"
            loop={true}
            height={600}
            autoHeight={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
          >
            {slidesElements}
          </Swiper>
        </div>
      )}
    </>
  );
}
