import React from "react";
import "./Modal.css";

interface ModalProps {
  popupTitle: string | undefined;
  setOpenModal: (open: boolean) => void;
  functionToExecute: (groupName: string) => void;
  groupNames: string[];
}

const Modal: React.FC<ModalProps> = ({ popupTitle, setOpenModal, functionToExecute, groupNames }) => {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        {/* <div className="titleCloseBtn">
          <button onClick={() => setOpenModal(false)}>X</button>
        </div> */}

        <div className="title">
          <h1>{popupTitle}</h1>
        </div>

        <div className="footer3">
          {/* Dynamically create buttons based on groupNames */}
          {groupNames.map((groupName, index) => (
            <button
              key={index}
              onClick={() => {
                functionToExecute(groupName); // Pass the group name to the function
                setOpenModal(false);
              }}
            >
              {groupName.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;
