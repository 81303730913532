import React, { useEffect, useState, useRef, useCallback } from 'react';
import './clientDashboardMain.css';
import './cilent-dashboard-util/notesUtil.css'
import ClientNavbar from '../client-dashboard/clientNavbar/clientNavbar';
import ClientDashboardMainEditableForm from './clientDashboardMainEditableForm';
import NotesUtil from './cilent-dashboard-util/notesUtil';
import NotesFormUtil from './cilent-dashboard-util/notesFormUtil';
import { FaPlusCircle } from "react-icons/fa";
import LoadingSpinner from '../backend/components/LoadingSpinner'; // Import the loading spinner
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css';

// Assets
import detectorCardPicture from '../img/detector/2025Detector4.jpg';

// Detectors Data
// import detecData from './fake-detectors.json';
// import fakeNotes from './fakeNotes.json'

// ------------- Popup imports -------------
import Modal from "../components/UtilComponents/Modal";
import { useAuth } from '../Auth';
// ------------- Popup imports -------------

// Particles Background Imports
import ParticleBackground from '../components/particlesbackgroundForScrollable';
import { loadFull } from 'tsparticles';
import { loadPolygonMaskPlugin } from 'tsparticles-plugin-polygon-mask';
import { ZIndex, type Engine } from 'tsparticles-engine';

// CSRF imports
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export let globalFileHolder: File | null = null;

// Interface for reading image
interface DetectorPhoto {
    DETECTOR_ID: string;
    DETECTOR_SITE_NAME: string;
    DETECTOR_IMG: any;
}

// Interface for reading notes
interface DetectorNotes {
    NOTE_ID: number,
    DETECTOR_ID: string,
    NOTE_TITLE: string,
    NOTE_SUBMIT_DATE: string,
    NOTE_EDIT_DATE: any,
    NOTE_BODY: string
}

// Define the Detector type for TypeScript
interface Detector {
    ip: string
    last_active: string
    mac: string
    online: boolean
    status: {
        fw_version: any
        gps_fix: boolean
        gps_lat: any
        gps_long: any
        linux: boolean
        memory: number
        site_name: string
        test_mode: boolean
        thr: any
        up_since: string
    }
}

export const getTodayDateString = (): string => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format: "YYYY-MM-DD"
};

function determineColumns(numDetec: number) {
    let col = 0;
    let lastRow = 0;

    if (numDetec % 3 === 0) {
        col = 3;
    } else if (numDetec % 2 === 0) {
        col = 2;
    } else if (numDetec % 3 === 1) {
        col = 3;
        lastRow = 1;
    } else if (numDetec % 3 === 2) {
        col = 3;
        lastRow = 2;
    }

    return { col, lastRow };
}

const clientDashboardMain = () => {

    // --------------------------------------------- Getting MAC from new API START ---------------------------------------------
    const [macsFromAPI, setMacsFromAPI] = useState<Detector[]>([]);
    const [detectorJson, setDetectorJson] = useState<Detector[]>([]); // Initialize with empty array or macsFromAPI
    const [savedGroup, setSavedGroup] = useState<string | null>(localStorage.getItem("selectedGroup"));

    const getCsrfToken = (): string | null => {
        const metaTag = document.querySelector('meta[name="csrf-token"]');
        return metaTag ? metaTag.getAttribute('content') : null; // Return null if the meta tag is not found
    };

    const fetchMACsFromAPI = async (selectedGroup: string | null) => {
        try {
            if (!selectedGroup) return; // Prevent fetch if no group is selected

            const response = await fetch(`/apidet/${selectedGroup}/`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'same-origin',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: Detector[] = await response.json();
            setMacsFromAPI(data); // Assign the fetched data to state
        } catch (err) {
            if (err instanceof Error) {
                console.error(err.message);
            } else {
                console.error('An unknown error occurred');
            }
        }
    };

    // Fetch on initial load and then every 5 seconds
    useEffect(() => {
        if (savedGroup) {
            fetchMACsFromAPI(savedGroup);

            const interval = setInterval(() => {
                fetchMACsFromAPI(savedGroup);
            }, 5000); // 5000 ms = 5 seconds

            // Clear interval on component unmount or when group changes
            return () => clearInterval(interval);
        }
    }, [savedGroup]); // Runs when savedGroup changes

    // Whenever macsFromAPI changes, update detectorJson
    useEffect(() => {
        setDetectorJson(macsFromAPI); // Sync macsFromAPI with detectorJson
    }, [macsFromAPI]);

    // console.log(macsFromAPI)

    // --------------------------------------------- Getting MAC from new API END ---------------------------------------------

    const numDetec = Object.keys(macsFromAPI).length;
    const { col, lastRow } = determineColumns(numDetec);
    const [globalCol, setGlobalCol] = useState(0);
    const [galleryData, setGalleryData] = useState<DetectorPhoto[]>([]);


    // --------------------------------------------- Popup START ---------------------------------------------
    const { isAuthenticated, getDashboardPermissions } = useAuth();
    const [clientPermissionGroups, setClientPermissionGroups] = useState<{ [key: string]: any }>({});

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [groups, setGroups] = useState<string[]>([]);
    const [chosenGroupCallback, setChosenGroupCallback] = useState<((groupName: string) => void) | null>(null);

    useEffect(() => {
        const fetchClientPermissions = async () => {
            const permissions2 = await getDashboardPermissions(); // Await the promise
            setClientPermissionGroups(permissions2 || {});

            // Return a promise that resolves when a group is selected
            return new Promise((resolve) => {
                setChosenGroupCallback(() => (selectedGroup: string) => {
                    setModalOpen(false); // Close the modal
                    localStorage.setItem("selectedGroup", selectedGroup); // Save to local storage
                    resolve(selectedGroup); // Resolve the promise with the selected group
                });
            });
        };

        if (isAuthenticated) {
            fetchClientPermissions();
        }
    }, [isAuthenticated, getDashboardPermissions]);

    useEffect(() => {
        if (!savedGroup && clientPermissionGroups && Object.keys(clientPermissionGroups).length > 1) {
            // Extract the group names with a true value
            const groupNames = Object.keys(clientPermissionGroups).filter(groupName => clientPermissionGroups[groupName]);

            // Set the popup to open and assign the name of the buttons based on the names of the read groups
            setGroups(groupNames);
            setModalOpen(true);
        }

        if (clientPermissionGroups && Object.keys(clientPermissionGroups).length === 1) {
            // Extract the group name with a true value
            const firstGroup = Object.entries(clientPermissionGroups)[0]; // Get the first key-value pair

            // Check if the user has access to that specified table
            if (firstGroup[1] === true) {
                handleFunction(firstGroup[0]);
            } else {
                console.warn("This user does not have permission to access this table");
            }
        }
    }, [clientPermissionGroups, savedGroup]); // Runs after `clientPermissionGroups` updates

    // const tempName = Object.keys(clientPermissionGroups).filter(groupName => clientPermissionGroups[groupName]);

    // useEffect(() => {
    //     setGroups(tempName);
    // console.log(tempName)
    // }, [tempName]);

    useEffect(() => {
        if (clientPermissionGroups && Object.keys(clientPermissionGroups).length > 0) {
            const tempName = Object.keys(clientPermissionGroups).filter(
                groupName => clientPermissionGroups[groupName]
            );
            setGroups(tempName);
        } else {
            // Optional: Reset groups if no data
            setGroups([]);
        }
    }, [clientPermissionGroups]);


    // Check localStorage for selected group whenever savedGroup or groups change
    useEffect(() => {
        const storedGroup = localStorage.getItem("selectedGroup");

        // Ensure that groups is populated before performing the check
        if (groups.length === 0) {
            return; // Don't proceed until groups are populated
        }

        if (storedGroup && !groups.includes(storedGroup)) {
            console.warn(`The selected group "${storedGroup}" is not valid.`);
            localStorage.setItem("selectedGroup", groups[0]); // Save to local storage
            return; // Exit early after redirect
        }

        // If the stored group is valid, set it to state
        setSavedGroup(storedGroup);
    }, [groups]); // Runs when `groups` changes


    const handleFunction = (selectedGroup: string) => {
        if (chosenGroupCallback) {
            chosenGroupCallback(selectedGroup); // Trigger the callback with the selected group
            localStorage.setItem("selectedGroup", selectedGroup); // Save to local storage
            setSavedGroup(selectedGroup); // Update the state with the selected group
        }
    };

    // --------------------------------------------- Popup END ---------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);

    // Preprocess galleryData once into a Map
    const photoMap = new Map(galleryData.map((photo) => [photo.DETECTOR_ID, photo]));

    const fetchGalleryPhotos = async () => {
        try {
            const response = await fetch('/api/detectordashimgs', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'same-origin',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: DetectorPhoto[] = await response.json();
            setGalleryData(data); // Assign the fetched gallery data to state
            // console.log(data)

        } catch (err) {
            // Type assertion to 'Error' to access the message property
            if (err instanceof Error) {
                console.error(err.message); // Handle error
            } else {
                console.error('An unknown error occurred'); // Fallback for unknown errors
            }
        }
    };

    useEffect(() => {
        fetchGalleryPhotos();
    },

        []); // Empty dependency array to run once on mount

    const addGalleryPhotosIfExits = async (detecID: string, titleAndImage: DetectorPhoto) => {
        try {

            let csrfToken = cookies.get('csrftoken'); // Get the CSRF token from cookies

            if (!csrfToken) {
                csrfToken = getCsrfToken();
                if (!csrfToken) {
                    console.error('CSRF token not found');
                    return; // Handle the absence of the CSRF token appropriately
                }
            }

            // Create FormData object
            const formData = new FormData();
            formData.append("DETECTOR_ID", titleAndImage.DETECTOR_ID.toString()); // Convert number to string
            formData.append("DETECTOR_SITE_NAME", titleAndImage.DETECTOR_SITE_NAME);
            formData.append("DETECTOR_IMG", titleAndImage.DETECTOR_IMG); // Append the file

            // Check if an item with the given DETECTOR_ID exists in galleryData
            if (photoMap.has(detecID)) {
                const response = await fetch(`/api/detectordashimgs/${detecID}/`, {
                    method: "PUT",
                    headers: {
                        'X-CSRFToken': csrfToken,
                    },
                    credentials: 'same-origin',
                    body: formData
                });


                if (!response.ok) {
                    const errorText = await response.text(); // Fetch response body for more details
                    throw new Error(`Server error: ${response.status} - ${errorText}`);
                }

                const result = await response.json();
                console.table(result.value);
            }
            else {
                const response = await fetch(`/api/detectordashimgs/`, {
                    method: "POST",
                    headers: {
                        'X-CSRFToken': csrfToken,
                    },
                    credentials: 'same-origin',
                    body: formData
                });


                if (!response.ok) {
                    const errorText = await response.text(); // Fetch response body for more details
                    throw new Error(`Server error: ${response.status} - ${errorText}`);
                }

                const result = await response.json();
                console.table(result.value);
            }

        } catch (err) {
            // Type assertion to 'Error' to access the message property
            if (err instanceof Error) {
                console.error(err.message); // Handle error
            } else {
                console.error('An unknown error occurred'); // Fallback for unknown errors
            }
        }
    };

    useEffect(() => {
        const updateGridStyle = () => {
            let columns = 3; // Default to 3 columns

            if (window.innerWidth > 1780) {
                columns = col; // Use the calculated column number
                setGlobalCol(0);
            } else if (window.innerWidth <= 1780 && window.innerWidth > 1200) {
                columns = 2; // Switch to 2 columns for medium screens
                setGlobalCol(2);
            } else {
                columns = 1; // Switch to 1 column for small screens
                setGlobalCol(1);
            }

            // Set the CSS variable for the number of columns
            document.documentElement.style.setProperty('--grid-columns', columns.toString());
        };

        updateGridStyle();
        window.addEventListener('resize', updateGridStyle);

        return () => window.removeEventListener('resize', updateGridStyle);
    }, [col]);

    // Function to render the detector card
    const renderCard = (detector: Detector, index: number, extraClass = "") => {
        const {
            mac,
            online,
            status: {
                gps_lat,
                gps_long,
                site_name
            },
        } = detector

        // Filter the relevant photo for this detector
        const matchingPhoto = photoMap.get(detector.mac);

        return (
            <div key={index} className={`detecDash-card ${extraClass}`} onClick={(event) => handleDetectorFormEditClick(event, detector)}>


                <div className="smaller-text-card">
                    {/* Display photo if it matches, otherwise show default */}


                    {!matchingPhoto ? (
                        <div className="myImage" style={{ backgroundImage: `url(${detectorCardPicture})` }}>
                            <div className="myImage-overlay">
                                <div className="show-plus">
                                    <FaPlusCircle className="plus-circle" />
                                </div>
                            </div>
                        </div>
                    ) : !matchingPhoto.DETECTOR_IMG ? (
                        <LoadingSpinner />
                    ) : (
                        <div
                            className="myImage"
                            style={{ backgroundImage: `url(${matchingPhoto.DETECTOR_IMG})` }}
                        />
                    )}



                    <div className='adjust-smaller-text-card-title'>
                        <h1>{site_name === "" ? mac : site_name}</h1>
                    </div>
                    <div className="smaller-text-cardInfo">
                        <p>{online === true ? "🟢 Online" : "🔴 Offline"}</p>
                        <p>GPS Quality: {online === true ? "Good" : "Bad"}</p>
                        <p>Location: {parseFloat(gps_lat).toFixed(2)} | {parseFloat(gps_long).toFixed(2)}</p>
                    </div>
                </div>

            </div>
        );
    };


    /* ------------------------- EDITABLE IMAGE AND NAME -----------------------------------------------  */
    const [editDetecFormId, setEditDetecFormId] = useState('');

    const [editDetecFormData, setEditDetecFormData] = useState({
        ip: '',
        last_active: '',
        mac: '',
        online: false,
        status: {
            fw_version: null as any,
            gps_fix: false,
            gps_lat: '',
            gps_long: '',
            linux: false,
            memory: 0,
            site_name: '',
            test_mode: false,
            thr: null as any,  // Allow number or null
            up_since: '',
        },
    });


    const [editIMGFormData, setEditIMGFormData] = useState({
        DETECTOR_SITE_NAME: '',
        DETECTOR_IMG: '', // The initial value is null, and it can be a File object
    });

    const handleEditDetectorFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const fieldName = event.target.name; // Name of the input field

        if (event.target.type === 'file') {
            const file = event.target.files?.[0]; // Get the first file from the input
            if (file) //Used for the image API
            {
                globalFileHolder = file;
                // Create a FileReader to generate a preview URL
                const reader = new FileReader();
                reader.onload = (e) => {
                    const filePreviewUrl = e.target?.result as string; // Get the preview URL
                    // Update the form data with the preview URL
                    setEditIMGFormData((prevState) => ({
                        ...prevState,
                        [fieldName]: filePreviewUrl, // Update DETECTOR_IMG with the preview URL
                    }));
                };
                reader.readAsDataURL(file); // Read the file as a data URL
            }
        }
        else // used for the json form
        {
            const fieldValue = event.target.value; // Value of the input field for non-file inputs
            setEditDetecFormData((prevState) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    [fieldName]: fieldValue,  // Ensure that 'site_name' gets updated here
                }
            }));
        }
    };

    const handleEditDetectorFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Construct the edited feature with the correct structure
        const editedFeature = {
            ip: editDetecFormData.ip,
            last_active: editDetecFormData.last_active,
            mac: editDetecFormData.mac,
            online: editDetecFormData.online,
            status: {
                fw_version: editDetecFormData.status.fw_version,
                gps_fix: editDetecFormData.status.gps_fix,
                gps_lat: editDetecFormData.status.gps_lat,
                gps_long: editDetecFormData.status.gps_long,
                linux: editDetecFormData.status.linux,
                memory: editDetecFormData.status.memory,
                site_name: editDetecFormData.status.site_name,
                test_mode: editDetecFormData.status.test_mode,
                thr: editDetecFormData.status.thr,
                up_since: editDetecFormData.status.up_since
            }
        }

        const editedFeature2 = {
            DETECTOR_ID: editDetecFormId,
            DETECTOR_SITE_NAME: editDetecFormData.status.site_name,
            DETECTOR_IMG: globalFileHolder,
        };

        const newDetecs = [...detectorJson]
        const newDetecs2 = [...galleryData]

        // Editing correct row when clicking on save button
        const index = detectorJson.findIndex((myContact) => myContact.mac === editDetecFormId);
        const index2 = galleryData.findIndex((element) => element.DETECTOR_ID === editDetecFormId);

        // Upload to Detector json
        newDetecs[index] = editedFeature;
        setDetectorJson(newDetecs);

        // Upload to image api
        newDetecs2[index2] = editedFeature2;
        setGalleryData(newDetecs2);

        // Handles the case in which the user clicks on save without making any changes to the photos
        if (globalFileHolder instanceof File) {
            // Function to add photo to API and refresh the page
            const handleEditDetectorSubmit = async () => {
                await addGalleryPhotosIfExits(editDetecFormId, editedFeature2);
                fetchGalleryPhotos(); // Refresh gallery data
            };

            handleEditDetectorSubmit();

            // Adding the detector object to the local image array
            if (!photoMap.has(editDetecFormId)) {
                galleryData.push(editedFeature2);
                photoMap.set(editDetecFormId, editedFeature2); // Keep photoMap in sync
            }

        }

        else {
            handleCancelEdit();
        }

        setEditDetecFormId('');
    }

    const handleDetectorFormEditClick = (event: React.MouseEvent<HTMLDivElement>, tempDetec: Detector) => {
        window.scrollTo(0, 0);
        event.preventDefault();

        setEditDetecFormId(tempDetec.mac);

        const formValues = {
            ip: tempDetec.ip,
            last_active: tempDetec.last_active,
            mac: tempDetec.mac,
            online: tempDetec.online,
            status: {
                fw_version: tempDetec.status.fw_version,
                gps_fix: tempDetec.status.gps_fix,
                gps_lat: tempDetec.status.gps_lat,
                gps_long: tempDetec.status.gps_long,
                linux: tempDetec.status.linux,
                memory: tempDetec.status.memory,
                site_name: tempDetec.status.site_name,
                test_mode: tempDetec.status.test_mode,
                thr: tempDetec.status.thr,
                up_since: tempDetec.status.up_since
            }
        };

        const existingItem = photoMap.get(tempDetec.mac);
        globalFileHolder = existingItem?.DETECTOR_IMG;

        const formValues2 = {
            DETECTOR_SITE_NAME: tempDetec.status.site_name,
            DETECTOR_IMG: existingItem?.DETECTOR_IMG,
        };

        setEditDetecFormData(formValues);
        setEditIMGFormData(formValues2);

    };

    const handleCancelEdit = () => {
        setEditDetecFormId(''); // This will hide the EditableForm and show the DetectorCards again (change to null)
        setEditNotesId(0);
        window.scrollTo(0, 0);
    };

    /* ------------------------- EDITABLE IMAGE AND NAME -----------------------------------------------  */

    /* ---------------------------------- EDITABLE NOTES -----------------------------------------------  */

    const [editNotesId, setEditNotesId] = useState(0);

    // State to control visibility of the form
    const [isNotesFormVisible, setNotesFormVisible] = useState(false);
    const formRef = useRef<HTMLDivElement | null>(null);
    const [listOfNotes, setListOfNotes] = useState<DetectorNotes[]>([]);

    const fetchNotes = async () => {
        try {
            const response = await fetch('/api/detectordashnotes/', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'same-origin',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: DetectorNotes[] = await response.json();
            setListOfNotes(data); // Assign the fetched gallery data to state
            // console.log(data)

        } catch (err) {
            // Type assertion to 'Error' to access the message property
            if (err instanceof Error) {
                console.error(err.message); // Handle error
            } else {
                console.error('An unknown error occurred'); // Fallback for unknown errors
            }
        }
    };

    const createNewRowInDB = async (myNotes: DetectorNotes) => {
        try {

            let csrfToken = cookies.get('csrftoken'); // Get the CSRF token from cookies

            if (!csrfToken) {
                csrfToken = getCsrfToken();
                if (!csrfToken) {
                    console.error('CSRF token not found');
                    return; // Handle the absence of the CSRF token appropriately
                }
            }

            // Check if an item with the given DETECTOR_ID exists in galleryData
            const response = await fetch(`/api/detectordashnotes/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                credentials: 'same-origin',
                body: JSON.stringify(myNotes)
            });


            if (!response.ok) {
                const errorText = await response.text(); // Fetch response body for more details
                throw new Error(`Server error: ${response.status} - ${errorText}`);
            }

            await response.json();
            // const result = await response.json();
            // console.table(result.value);


        } catch (err) {
            // Type assertion to 'Error' to access the message property
            if (err instanceof Error) {
                console.error(err.message); // Handle error
            } else {
                console.error('An unknown error occurred'); // Fallback for unknown errors
            }
        }

        fetchNotes();
    };

    const deleteRowFromDB = async (noteID: number) => {
        try {

            let csrfToken = cookies.get('csrftoken'); // Get the CSRF token from cookies

            if (!csrfToken) {
                csrfToken = getCsrfToken();
                if (!csrfToken) {
                    console.error('CSRF token not found');
                    return; // Handle the absence of the CSRF token appropriately
                }
            }

            const response = await fetch(`/api/detectordashnotes/${noteID}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                credentials: 'same-origin',
            });

            if (!response.ok) {
                const errorText = await response.text(); // Fetch response body for more details
                throw new Error(`Server error: ${response.status} - ${errorText}`);
            }

            // Only attempt to parse JSON if there's content
            if (response.headers.get("content-length") !== "0") {
                await response.json();
            }

        } catch (err) {
            if (err instanceof Error) {
                console.error(err.message);
            } else {
                console.error("An unknown error occurred");
            }
        }

        fetchNotes();
    };

    const updateRowInDB = async (noteID: number, myNotes: DetectorNotes) => {
        try {

            let csrfToken = cookies.get('csrftoken'); // Get the CSRF token from cookies

            if (!csrfToken) {
                csrfToken = getCsrfToken();
                if (!csrfToken) {
                    console.error('CSRF token not found');
                    return; // Handle the absence of the CSRF token appropriately
                }
            }

            const response = await fetch(`/api/detectordashnotes/${noteID}/`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                credentials: 'same-origin',
                body: JSON.stringify(myNotes)
            });

            if (!response.ok) {
                const errorText = await response.text(); // Fetch response body for more details
                throw new Error(`Server error: ${response.status} - ${errorText}`);
            }

            // Only attempt to parse JSON if there's content
            if (response.headers.get("content-length") !== "0") {
                await response.json();
            }

        } catch (err) {
            if (err instanceof Error) {
                console.error(err.message);
            } else {
                console.error("An unknown error occurred");
            }
        }

        fetchNotes();
    };

    useEffect(() => {
        fetchNotes();
    },
        []); // Empty dependency array to run once on mount


    listOfNotes.sort((a, b) => {
        const dateDiff = new Date(b.NOTE_SUBMIT_DATE).getTime() - new Date(a.NOTE_SUBMIT_DATE).getTime();

        // If dates are the same, sort by NOTE_ID in descending order
        if (dateDiff === 0) {
            return b.NOTE_ID - a.NOTE_ID;
        }

        return dateDiff;
    });

    // Finds the last id of the list
    const maxId = listOfNotes.reduce((max, note) => Math.max(max, note.NOTE_ID), 0);

    // let maxId = 0;

    // for (const key in listOfNotes)
    // {
    //     // console.log("listOfNotes", "[",key,"]", Object.values(listOfNotes[key]))
    //     if (listOfNotes[key].NOTE_ID > maxId)
    //     {
    //         maxId = listOfNotes[key].NOTE_ID
    //     }
    // }

    const handleShowForm = () => {
        setNotesFormVisible(true);

        // Scroll to the end of the table
        setTimeout(() => {
            if (formRef.current) {
                formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 100);
    };

    const handleCancelNotesButton = () => {
        setEditNotesId(0); // This will hide the EditableForm and show the DetectorCards again (change to null)
        setNotesFormVisible(false)
    };

    const handleNotesEditClick = (noteId: number, event: React.MouseEvent<HTMLDivElement>, tempNote: DetectorNotes) => {
        event.preventDefault();

        // Set the edit notes ID to the selected note's ID
        setEditNotesId(noteId);

        setEditNotesFormData({
            ...tempNote,
            DETECTOR_ID: String(tempNote.DETECTOR_ID),
            NOTE_EDIT_DATE: tempNote.NOTE_EDIT_DATE && tempNote.NOTE_EDIT_DATE !== '' ? tempNote.NOTE_EDIT_DATE : null, // ✅ Convert empty string to null
        });

    };

    // Function to delete note
    const handleNotesDeleteClick = (noteId: number, event: React.MouseEvent<HTMLButtonElement>) => // Pass  (pilot, pilotId) later when integrating with db
    {
        event.preventDefault();

        const adjustedNotesList = [...listOfNotes];


        // Editing correct row when clicking on save button
        const indexForArray = listOfNotes.findIndex((myContact) => myContact.NOTE_ID === noteId); // For react
        // const indexForDB = pilot.id; // For DB

        // Splice function removes 1 element as stated by the second parameter at the "index" location for React
        adjustedNotesList.splice(indexForArray, 1);

        // Delete from DB
        deleteRowFromDB(noteId);

        // Update pilot array to not include the deleted flight
        setListOfNotes(adjustedNotesList);

        toast.success('Note deleted successfully!', {
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
                backgroundColor: '#2d2f31',
                color: 'white',
                fontSize: '16px',
            }
        });
    }

    // Add a day due to TSX and JSX timezone issues
    const formatDateString = (date: string): string => {
        const dateObj = new Date(date);
        const year = dateObj.getUTCFullYear();
        const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');  // Months are 0-indexed
        const day = dateObj.getUTCDate().toString().padStart(2, '0'); // Get day of the month
        return `${month}/${day}/${year}`; // Format date as MM-DD/YYYY
    };

    const [editNotesFormData, setEditNotesFormData] = useState({
        NOTE_ID: 0,
        DETECTOR_ID: '',
        NOTE_TITLE: '',
        NOTE_SUBMIT_DATE: '',
        NOTE_EDIT_DATE: null,
        NOTE_BODY: ''
    });

    const handleEditNotesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();

        const fieldName = event.target.name; // Name of the input field
        const fieldValue = event.target.value; // Value of the input field for non-file inputs

        setEditNotesFormData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue,
        }));
    };

    const handleEditNotesSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Construct the edited feature with the correct structure
        const editedFeature2 = {
            NOTE_ID: editNotesFormData.NOTE_ID,
            DETECTOR_ID: editDetecFormId,
            NOTE_TITLE: editNotesFormData.NOTE_TITLE,
            NOTE_SUBMIT_DATE: editNotesFormData.NOTE_SUBMIT_DATE,
            NOTE_EDIT_DATE: getTodayDateString(),
            NOTE_BODY: editNotesFormData.NOTE_BODY
        };

        if (editedFeature2.NOTE_TITLE === "") {
            toast.error('Note title cannot be empty', {
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                    backgroundColor: '#2d2f31',
                    color: 'white',
                    fontSize: '16px',
                }
            });

            return
        }

        if (editedFeature2.NOTE_BODY === "") {
            toast.error('Note body cannot be empty', {
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                    backgroundColor: '#2d2f31',
                    color: 'white',
                    fontSize: '16px',
                }
            });

            return
        }


        const newNotes = [...listOfNotes]

        // Editing correct row when clicking on save button
        const index2 = listOfNotes.findIndex((element) => element.NOTE_ID === editNotesId);


        newNotes[index2] = editedFeature2;
        setListOfNotes(newNotes);

        // Update note in db
        updateRowInDB(editNotesId, editedFeature2)

        toast.success('Note edited successfully!', {
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
                backgroundColor: '#2d2f31',
                color: 'white',
                fontSize: '16px',
            }
        });


        setEditNotesId(0);
    };

    const [addNotesFormData, setAddNotesFormData] = useState({
        NOTE_ID: 0,
        DETECTOR_ID: '',
        NOTE_TITLE: '',
        NOTE_SUBMIT_DATE: '',
        NOTE_EDIT_DATE: '',
        NOTE_BODY: ''
    });

    const handleAddNotesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault(); // Prevents form from doing post requests when submitted

        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        setAddNotesFormData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue,
        }));
    };

    const handleAddNotesSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevents form from doing post requests when submitted

        const addFeature2 = {
            NOTE_ID: maxId ? maxId + 1 : 1,
            DETECTOR_ID: editDetecFormId,
            NOTE_TITLE: addNotesFormData.NOTE_TITLE,
            NOTE_SUBMIT_DATE: getTodayDateString(),
            NOTE_EDIT_DATE: null,
            NOTE_BODY: addNotesFormData.NOTE_BODY
        };

        if (addFeature2.NOTE_TITLE === "") {
            toast.error('Note title cannot be empty', {
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                    backgroundColor: '#2d2f31',
                    color: 'white',
                    fontSize: '16px',
                }
            });

            return
        }

        if (addFeature2.NOTE_BODY === "") {
            // alert("Note body or title cannot be empty")
            toast.error('Note body cannot be empty', {
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                    backgroundColor: '#2d2f31',
                    color: 'white',
                    fontSize: '16px',
                }
            });

            return
        }

        // Pushing to the front of the array since it is already sorted by date
        listOfNotes.unshift(addFeature2)

        // Reset form after submission
        setAddNotesFormData({
            NOTE_ID: 0,
            DETECTOR_ID: '',
            NOTE_TITLE: '',
            NOTE_SUBMIT_DATE: '',
            NOTE_EDIT_DATE: '',
            NOTE_BODY: ''
        });

        setNotesFormVisible(false);

        // Add it to the DB
        // console.log(addFeature2)
        createNewRowInDB(addFeature2);

        toast.success('Note added successfully!', {
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
                backgroundColor: '#2d2f31',
                color: 'white',
                fontSize: '16px',
            }
        });

        // Clearing the form, hiding it, making it scroll to the bottom, flash blue, and notify the user of the addition
        // notifyClearHideScrollToEnd();

    };

    // console.log(localStorage)


    /* ---------------------------------- EDITABLE NOTEs-----------------------------------------------  */

    // Partices Background
    const particlesInit = useCallback(async (main: Engine) => {
        await loadFull(main);
        await loadPolygonMaskPlugin(main);
    }, []);

    const [width, setWidth] = useState(window.innerWidth);
    const [heightTopParticles, setHeightTopParticles] = useState(width * 0.36);

    useEffect(() => {
            function handleResize() {
                setWidth(window.innerWidth);
                setHeightTopParticles(width * 0.36);
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, [width]);

    return (
        <div className="detecDash-wrapper">

            <ClientNavbar setSavedGroup={setSavedGroup} groupCount={Object.keys(clientPermissionGroups).length} />

            <ParticleBackground
                particlesInitBackground={particlesInit}
                number={50}
                aspectRatio={width / heightTopParticles}
            />

            <ToastContainer /> {/* Handle toasts in this page */}

            {modalOpen &&
                <Modal
                    popupTitle={"Which group would you like to access?"}
                    setOpenModal={setModalOpen}
                    functionToExecute={handleFunction}
                    groupNames={groups}
                />}


            {editDetecFormId === '' ? (
                <div className="detecDash-wrapper2">
                    {Array.isArray(detectorJson) ? (
                        detectorJson.map((currentDetector, index) => {
                            // Handle center-single case (last row with 1 detector)
                            if (lastRow === 1 && index === numDetec - 1 && globalCol === 0) {
                                return renderCard(currentDetector, index, "center-single");
                            }

                            // Handle center-double case (last row with 2 detectors)
                            if (lastRow === 2 && index === numDetec - 2 && globalCol === 0) {
                                return (
                                    <div key={index} className="center-double">
                                        {renderCard(currentDetector, index)}
                                        {renderCard(detectorJson[index + 1], index + 1)}
                                    </div>
                                );
                            }

                            // Handle the case in which it shrunk to 2 columns and you have 1 left over
                            if (globalCol === 2) {
                                if (numDetec % 2 === 1 && index === numDetec - 1) {
                                    return renderCard(currentDetector, index, "center-single2");
                                } else {
                                    return renderCard(currentDetector, index);
                                }
                            }
                            // Back to mobile view you only need 1 column
                            if (globalCol === 1) {
                                return renderCard(currentDetector, index);
                            }

                            // Default case for all other detectors
                            if ((lastRow !== 1 && lastRow !== 2) || index < numDetec - (lastRow || 0)) {
                                return renderCard(currentDetector, index);
                            }
                        })
                    ) : (
                        <div className='empty-message'>
                            {/* <p>{typeof macsFromAPI === 'string' ? macsFromAPI : JSON.stringify(macsFromAPI)}</p> */}
                            <p>No detectors available in this group.</p>

                        </div>

                    )}

                </div>

            ) : (

                <div className='editor-plus-notes'>
                    {detectorJson
                        .filter((contact) => contact.mac === editDetecFormId)
                        .map((contact) => (
                            <ClientDashboardMainEditableForm
                                key={contact.mac}
                                editDetecFormData={editDetecFormData}
                                editIMGFormData={editIMGFormData}
                                handleEditDetectorFormChange={handleEditDetectorFormChange} // Pass the change function to keep the fields
                                handleCancelEdit={handleCancelEdit} // Pass the cancel function
                                handleEditDetectorFormSubmit={handleEditDetectorFormSubmit} // Pass the submit function to submit the changed fields
                            />
                        ))}

                    <div className='notes'>

                        <div className='main-title-and-add'>
                            <h1>Detector Notes</h1>
                            <FaPlusCircle className='plus-circle2' onClick={handleShowForm} />
                        </div>

                        <div className='all-notes'>

                            {isNotesFormVisible ?
                                (
                                    <div ref={formRef} className='notes-form-wrapper'>
                                        <form id="addNotesForm" className='notes-grid' onSubmit={handleAddNotesSubmit}>

                                            <div className='label-and-input'>
                                                <label> Title:</label>
                                                <input
                                                    className='notes-input'
                                                    type="text"
                                                    name='NOTE_TITLE'
                                                    value={addNotesFormData.NOTE_TITLE || ""}
                                                    placeholder='Enter Title'
                                                    onChange={handleAddNotesChange} />
                                            </div>

                                            <div className='label-and-input'>
                                                <label>Date:</label>
                                                <input
                                                    className='notes-input'
                                                    type="date"
                                                    name="NOTE_SUBMIT_DATE" // ✅ Matches the state key
                                                    value={addNotesFormData.NOTE_EDIT_DATE || getTodayDateString()} // ✅ Uses state, fallback to today
                                                    placeholder='Enter Date'
                                                    readOnly />
                                            </div>

                                            <div className='label-and-input'>
                                                <label>Note:</label>
                                                <textarea
                                                    className='notes-input'
                                                    name="NOTE_BODY"
                                                    value={addNotesFormData.NOTE_BODY || ""}
                                                    rows={6}
                                                    placeholder='Enter Note Body'
                                                    onChange={handleAddNotesChange}>
                                                </textarea>
                                            </div>

                                            <div className='notes-buttons'>
                                                <button type="submit" form="addNotesForm" >Save</button>
                                                <button type='button' onClick={handleCancelNotesButton} >Cancel</button>
                                            </div>
                                        </form>
                                    </div>

                                ) : (
                                    <div></div>
                                )}


                            {listOfNotes
                                .filter((note) => note.DETECTOR_ID === editDetecFormId) // Filter notes based on the selected detector ID
                                .map((note) => (
                                    editNotesId === note.NOTE_ID ? (
                                        <div ref={formRef} key={note.NOTE_ID} className='notes-form-wrapper'>
                                            <NotesFormUtil
                                                note={editNotesFormData}
                                                handleCancelNotesButton={handleCancelNotesButton}
                                                handleEditNotesChange={handleEditNotesChange}
                                                handleEditNotesSubmit={handleEditNotesSubmit}
                                                handleNotesDeleteClick={(event) => handleNotesDeleteClick(note.NOTE_ID, event)}
                                            />
                                        </div>
                                    ) : (
                                        <NotesUtil
                                            key={note.NOTE_ID}
                                            NoteTitle={note.NOTE_TITLE}
                                            NoteSubmitDate={note.NOTE_SUBMIT_DATE ? formatDateString(note.NOTE_SUBMIT_DATE) : null}
                                            NoteEditDate={note.NOTE_EDIT_DATE ? formatDateString(note.NOTE_EDIT_DATE) : null}
                                            NoteBody={note.NOTE_BODY}
                                            handleNotesEditClick={(event) => handleNotesEditClick(note.NOTE_ID, event, note)}
                                        />
                                    )
                                ))}

                        </div>

                    </div>

                </div>
            )}

            {/* <input type="file" onChange={(event) => handleFileUpload(event, 800, "testingChange")} /> */}

        </div>
    );
};

export default clientDashboardMain;