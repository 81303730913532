import React, { useRef, useState, useEffect } from 'react';
import '../../client-dashboard/clientDashboardMap.css';

//Assets
import fnnPopupLogo from '../../client-dashboard/client-dashboard-assets/IMG_FNNLogo_Big_WhiteBackground.png'
import redMarker from '../../client-dashboard/client-dashboard-assets/mapsmarker.png'
import yellowMarker from '../../client-dashboard/client-dashboard-assets/mapdetectoricon_yellow.png'
import greenMarker from '../../client-dashboard/client-dashboard-assets/mapdetectoricon_green.png'
import offlineMarker from '../../client-dashboard/client-dashboard-assets/mapsmarker_bw.png'
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { Icon } from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';

import { DetectorObject } from '../App'

export const redIcon = new Icon({
    iconUrl: redMarker,
    iconSize: [50, 50]
});

export const yellowIcon = new Icon({
    iconUrl: yellowMarker,
    iconSize: [50, 50]
});

export const BwIcon = new Icon({
    iconUrl: offlineMarker,
    iconSize: [50, 50]
});

export const greenIcon = new Icon({
    iconUrl: greenMarker,
    iconSize: [50, 50]
});

interface CenterMapOnDetectorProps {
    latitude: number;
    longitude: number;
    zoomLevel: number;
}

// Creating chart for collor coding the markers and text based on memory and gps fix
const getColorForMemory = (count: number) => {
    if (count > 15) return "red"; //red
    if (count > 0.5 && count <= 15) return "#d5b60a"; //yellow
    if (count <= 0.5) return "#008000"; //green
};

const getColorForGPSFix = (isOn: boolean) => {
    if (isOn === false) return "red"; //red
    if (isOn === true) return "#008000"; //green
};

const getColorForMarker = (isOn: boolean, memoryValue: string | number) => {
    // Check if memoryValue is a string containing letters
    if (typeof memoryValue === "string" && /[a-zA-Z]/.test(memoryValue)) {
        return redIcon; // Ignore and return redIcon
    }

    // Convert memoryValue to a number
    const numericMemory = Number(memoryValue);

    if (isOn === false) return BwIcon;
    if (numericMemory > 15 && isOn === true) return redIcon; //red
    if (numericMemory > 0.5 && numericMemory <= 15 && isOn === true) return yellowIcon; //yellow
    if (numericMemory <= 0.5 && isOn === true) return greenIcon; //green

    return redIcon; // Default to redIcon in case of unexpected values
};



const SearchControl: React.FC = () => {
    const map = useMap();

    useEffect(() => {
        const provider = new OpenStreetMapProvider();

        const searchControl = new (GeoSearchControl as any)({ // Fix: Explicitly cast to `any`
            provider,
            style: 'bar',
            showMarker: false,
            showPopup: true,
            popupFormat: ({ query, result }: { query: string; result: { label: string } }) =>
                `You searched for: ${query}<br>Result: ${result.label}`,
            searchLabel: 'Search Address or Zip',
        });

        map.addControl(searchControl);

        return () => {
            map.removeControl(searchControl);
        }; // Fix: Cleanup function now correctly returns void
    }, [map]);

    return null;
};

const CenterMapOnDetector: React.FC<CenterMapOnDetectorProps> = ({ latitude, longitude, zoomLevel }) => {
    const map = useMap();

    useEffect(() => {
        if (latitude && longitude) {
            map.flyTo([latitude, longitude], zoomLevel, {
                duration: 1.5,
            });
        }
    }, [latitude, longitude, zoomLevel, map]);

    return null;
};

interface MapViewProps {
    detectorData: DetectorObject[]; // Define the expected type for the prop
}

const MapView: React.FC<MapViewProps> = ({ detectorData }) => {

    // console.log("MapView rendering with data:", detectorData);

    // State to control visibility of the form
    const [isFormVisible, setFormVisible] = useState(true);
    const [isArrowVisible, setArrowVisible] = useState(false);
    const [isUserToggled, setUserToggled] = useState(false); // Track manual toggling of the form

    // Create a reference for the form wrapper for making it visible/hidden based on the button clicked
    const formWrapperRef = useRef(null);

    // Function for toggling the dashboard menu when on mobile
    const handleToggleForm = () => {
        setFormVisible((prev) => !prev);
        setUserToggled((prev) => !prev);
    };

    // Making the arrow to use the menu pop up when the user is on mobile
    const handle_window_resize = (window: number) => {
        if (window >= 700) {
            setFormVisible(true);
            // setUserToggled(false);
        }
        if (window < 700) {
            if (!isUserToggled) {
                setFormVisible(false);
            }
        }
    }

    // Function to check if the user is in mobile view in order to display the arrow button or not
    useEffect(() => {
        const updateGridStyle = () => {
            // Adjust the bottom position based on visibility
            const arrowBottomPosition = isFormVisible ? '97%' : '-2%';
            const zIndex = isFormVisible ? '2' : '0';

            document.documentElement.style.setProperty('--arrow-bottom-position', arrowBottomPosition);
            document.documentElement.style.setProperty('--z-index', zIndex);

            // Handle mobile view logic
            setArrowVisible(window.innerWidth < 700);
            handle_window_resize(window.innerWidth);

        };

        updateGridStyle();
        window.addEventListener('resize', updateGridStyle);

        return () => window.removeEventListener('resize', updateGridStyle);
    }, [isFormVisible]);

    const markerRefs = useRef<{ [key: string]: any }>({}); // Refs for markers

    // Variable to center the zoom on the detector
    const [centerCoordinates, setCenterCoordinates] = useState<{ latitude: number; longitude: number } | null>(null);

    // const detectorArray: DetectorObject[] = Object.values(detectorData);

    // Function to open popup for a specific detector
    const openPopup = (detectorId: string) => {
        const detector = Object.values(detectorData).find(
            (det) => det.mac === detectorId
        );

        if (detector && markerRefs.current[detectorId]) {
            const latitude = detector.status.gps_lat
            const longitude = detector.status.gps_long

            markerRefs.current[detectorId].openPopup();
            // Temporarily reset centerCoordinates to force re-render
            setCenterCoordinates(null);
            setTimeout(() => {
                setCenterCoordinates({ latitude, longitude });
            }, 0); // Small delay to allow React to reset
        }
    };

    // Separate detectors into online and offline groups
    const onlineDetectors = Object.values(detectorData).filter((detector) => detector.online === true);
    const offlineDetectors = Object.values(detectorData).filter((detector) => detector.online === false);

    // Search variables and funtions
    const [searchTerm, setSearchTerm] = useState<string>(""); // State to store the search term

    // Function to handle search input changes
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(e.target.value.toLowerCase()); // Update search term and convert to lowercase for case-insensitive matching
    };

    // Filter the detectors based on the search term
    const filteredOnlineDetectors = onlineDetectors.filter((detector) => {
        const siteName = detector.status?.site_name;
        const mac = detector.mac?.toLowerCase();

        if (!siteName) {
            return mac?.includes(searchTerm);
        }
        return siteName.toLowerCase().includes(searchTerm);
    });

    const filteredOfflineDetectors = offlineDetectors.filter((detector) => {
        const siteName = detector.status?.site_name;
        const mac = detector.mac?.toLowerCase();

        if (!siteName) {
            return mac?.includes(searchTerm);
        }
        return siteName.toLowerCase().includes(searchTerm);
    });

    const validDetector = Object.values(detectorData).find(
        (detector: any) =>
            detector.status?.gps_lat !== null &&
            detector.status?.gps_long !== null
    );

    // Provide default coordinates if validDetector is undefined
    const center: [number, number] = validDetector
        ? [validDetector.status.gps_lat, validDetector.status.gps_long]
        : [37.7749, -122.4194]; // Example fallback (San Francisco)


    return (
        <div style={{ width: "100%", height: "100%" }}> {/* Ensure it has a height */}

            <div className="map-and-dash">
                <div className='marker-legend'>
                    <div className='marker-and-description'>
                        <img src={greenMarker} className='legend-markers'></img>
                        <p>Memory ≤ 0.5</p>
                    </div>

                    <div className='marker-and-description'>
                        <img src={yellowMarker} className='legend-markers'></img>
                        <p>0.5 {`<`} Memory ≤ 15</p>
                    </div>

                    <div className='marker-and-description'>
                        <img src={redMarker} className='legend-markers'></img>
                        <p>Memory {`>`} 15</p>
                    </div>

                    <div className='marker-and-description'>
                        <img src={offlineMarker} className='legend-markers'></img>
                        <p>Detector is Offline</p>
                    </div>
                </div>

                <div className="my-map">
                    <MapContainer
                        center={center}
                        zoom={9}
                        style={{ width: "100%", height: "100%" }} // Ensure the map takes up the full container
                    >
                        {/* Add a TileLayer to display map tiles */}
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {/* Add the search control */}
                        <SearchControl />

                        {centerCoordinates &&
                            (
                                <CenterMapOnDetector
                                    latitude={centerCoordinates.latitude}
                                    longitude={centerCoordinates.longitude}
                                    zoomLevel={9} // Specify the desired zoom level
                                />
                            )}

                        {Object.values(detectorData).map((currentDetector) => {
                            // Check if both gps_lat and gps_long are not null or equal to 0.00000
                            if (
                                currentDetector &&
                                currentDetector.status &&
                                Object.keys(currentDetector.status).length > 0 && // Ensure status is not empty
                                currentDetector.status.gps_lat !== null &&
                                currentDetector.status.gps_long !== null &&
                                Math.abs(Number(currentDetector.status.gps_lat)) > 1e-6 &&
                                Math.abs(Number(currentDetector.status.gps_long)) > 1e-6
                            ) {
                                // console.log(getColorForMemory(Number(currentDetector.status.memory)))
                                return (
                                    <Marker
                                        key={currentDetector.mac}
                                        position={[currentDetector.status.gps_lat, currentDetector.status.gps_long]}
                                        icon={getColorForMarker(currentDetector.online, Number(currentDetector.status.memory))}
                                        ref={(el) => (markerRefs.current[currentDetector.mac] = el)} // Marker reference to open popup on side bar
                                    >
                                        <Popup>
                                            <img src={fnnPopupLogo} alt="" className="fnn-popup-logo" />
                                            Detector: {currentDetector.status.site_name === "" ? currentDetector.mac : currentDetector.status.site_name} <br />
                                            MAC: {currentDetector.mac} <br />
                                            Status: {currentDetector.online === true ? "🟢 Online" : "🔴 Offline"}<br />
                                            Latitude: {currentDetector.status.gps_lat}<br />
                                            Longitude: {currentDetector.status.gps_long}<br />
                                            Memory: <p style={{ color: getColorForMemory(Number(currentDetector.status.memory)), display: "inline", margin: 0 }}>
                                                {currentDetector.status.memory}
                                            </p><br />
                                            GPS Fix: <p style={{ color: getColorForGPSFix(currentDetector.status.gps_fix), display: "inline", margin: 0 }}>
                                                {currentDetector.status.gps_fix === true ? "Yes" : "No"}
                                            </p>
                                        </Popup>
                                    </Marker>
                                );
                            }
                            // Do nothing if the condition is not met
                            return null;
                        })}
                    </MapContainer>
                </div>

                <div className="arrow-and-dash">
                    {isArrowVisible && (
                        <div
                            className="circle-around-arrow"
                            onClick={handleToggleForm}
                        >
                            {isFormVisible ? <IoIosArrowDown className="arrow-icon" /> : <IoIosArrowUp className="arrow-icon" />}
                        </div>
                    )}

                    <div ref={formWrapperRef} className={`my-dash ${isFormVisible ? "visible" : ""}`}>
                        <h1>Status</h1>
                        <div className="input-box">
                            <input
                                type="text"
                                name="detec-search"
                                placeholder="Search Detectors"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="online-offline">
                            <h2>Online</h2>
                            <div className="detec-group">
                                {filteredOnlineDetectors.map((detector) => {
                                    if (
                                        detector.status.gps_lat !== null &&
                                        detector.status.gps_long !== null &&
                                        Math.abs(Number(detector.status.gps_lat)) > 1e-6 &&
                                        Math.abs(Number(detector.status.gps_long)) > 1e-6
                                    ) {
                                        return (
                                            <p
                                                key={detector.mac}
                                                onClick={() => {
                                                    openPopup(detector.mac);
                                                    if (window.innerWidth < 700) {
                                                        setFormVisible((prev) => !prev);
                                                        setUserToggled((prev) => !prev);
                                                    }
                                                }}
                                            >
                                                🟢 {detector.status.site_name || detector.mac}
                                            </p>
                                        );
                                    }
                                    return null;
                                })}
                            </div>

                            <h2>Offline</h2>
                            <div className="detec-group">
                                {filteredOfflineDetectors.map((detector) => {
                                    if (
                                        detector.status.gps_lat !== null &&
                                        detector.status.gps_long !== null &&
                                        Math.abs(Number(detector.status.gps_lat)) > 1e-6 &&
                                        Math.abs(Number(detector.status.gps_long)) > 1e-6
                                    ) {
                                        return (
                                            <p
                                                key={detector.mac}
                                                onClick={() => {
                                                    openPopup(detector.mac);
                                                    if (window.innerWidth < 700) {
                                                        setFormVisible((prev) => !prev);
                                                        setUserToggled((prev) => !prev);
                                                    }
                                                }}
                                            >
                                                🔴 {detector.status.site_name || detector.mac}
                                            </p>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MapView