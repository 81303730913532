import React, { useState } from 'react'
import './clientNavbar.css'
import { useAuth } from '../../Auth';
import { useNavigate } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { FaExchangeAlt } from "react-icons/fa";

interface ClientNavbarProps {
  setSavedGroup?: React.Dispatch<React.SetStateAction<string | null>>; // Optional
  groupCount?: number; // Optional
}


// Assets
import fnnNavLogo from '../../img/FNNLogoWithWhiteFont-07.png'

const clientNavbar = ({ setSavedGroup, groupCount }: ClientNavbarProps) => {

  // Setting the variables to be initially false until the toggleMenu function is called
  const [mobileMenu, setMobileMenu] = useState(false);
  function isAlreadyInHomePage() {
    if (window.location.pathname === "/") {
      return true;
    }
    return false;
  }

  const toggleMenu = () => {
    //If statement to check if the mobile menu is false and setting it to true
    mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
  }

  /* ---------------------------- Section responsible for checking if the user is logged in or not START ----------------------- */

  const { username, first_name } = useAuth();

  const navigate = useNavigate();

  const handleAuthRedirection = () => {
    navigate('/login'); // Replace with your desired route
  };

  /* ---------------------------- Section responsible for checking if the user is logged in or not END ------------------------- */

  return (
    <nav className='clientNav'>
      {/* Check if user is in the home page or not to either scroll him up to home or take him to home page if not */}
      {isAlreadyInHomePage() ? (
        <a href='/'> {/* Making logo image scroll back to top of home back*/}
          <img src={fnnNavLogo} alt="" className='fnnlogo' />
        </a>
      ) : (

        <a href='/'>
          <img src={fnnNavLogo} alt="" className='fnnlogo' />
        </a>
      )}

      <ul className={`mobile-menu ${mobileMenu ? 'show' : 'hide'}`}>
        <li
          style={{ display: groupCount && groupCount > 1 ? 'block' : 'none' }}  // Conditionally render based on groupCount
          onClick={() => {
            // console.log("before", localStorage)
            localStorage.removeItem("selectedGroup"); // Clear the stored group
            if (setSavedGroup) {
              setSavedGroup(null); // Only call setSavedGroup if it's provided
            }
            // window.location.reload(); // Refresh the page to reflect changes
            setMobileMenu(false); // Hide mobile menu on click
          }}
        >
          <FaExchangeAlt className="change-detector-list-icon" />
        </li>

        <li><a href='/fnn_client_dashboard_map' className='bnt-header'>Map</a></li>
        <li><a href='/fnn_client_dashboard' className='bnt-header'>Details</a></li>
        <li><a href='/fnn_client_dashboard_documents' className='bnt-header'>Documents</a></li>
      </ul>

      <div
        onClick={handleAuthRedirection}
        className="user-icon"
      >
        <svg
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: '25px', height: '25px' }}
        >
          <g id="SVGRepo_iconCarrier">
            <path
              d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z"
              fill="#28aae1"
            />
            <path
              d="M14 12C14 10.3431 12.6569 9 11 9H5C3.34315 9 2 10.3431 2 12V15H14V12Z"
              fill="#28aae1"
            />
          </g>
        </svg>
        {first_name !== "" ? first_name : username.split("@")[0]}
      </div>

      <Bars3Icon className="menu-icon" onClick={toggleMenu} />

      {/* <img src={menuIcon} alt="" className='menu-icon' onClick={toggleMenu}/> */}


    </nav>
  )
}

export default clientNavbar