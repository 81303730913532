import React from 'react'

const DocumentUtil = (docProps: any) => {
    return (
        <div className='document-card' >
            {docProps.icon}
            <h1>{docProps.docTitle} </h1>
            <p> {docProps.docContent}</p>
        </div>
    )
    }

export default DocumentUtil