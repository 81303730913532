import '../css/hrl-mapping.css';
import {
  LazyLoadImage,
  LazyLoadComponent,
} from 'react-lazy-load-image-component';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useState } from 'react';

function HrlMapping() {
  const Dashboard = require('../img/mapping/dashboard_new_logo.jpg'); // Updated to 2024 dashboard
  const Dashboard_LazyLoad = require('../img/mapping/dashboard-lazyload.png'); // Updated to 2024 dashboard

  const HRLDashboardImg = require('../img/mapping/HRLDashboard.png');
  const HRLDashboardImg_LazyLoad = require('../img/mapping/HRLDashboard-lazyload.jpg');

  const WorldMap = require('../img/mapping/worldmap.mov');
  const DashDemo = require('../img/mapping/HRLDashboardWebsiteVideo.mp4');
  const WorldMap_LazyLoadImg = require('../img/mapping/worldmap-lazyload.png');

  const totalStrikes = require('../img/mapping/Total strikes.png');
  const hrlStrikes = require('../img/mapping/hrl strikes.png');
  const ArrowRight = require('../img/mapping/arrowRight.png');

  // This is used to check if the video loaded and display a lazy image while the video loads
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);


  function IntroVideo() {
    return (
      <div className="relative bg-black pb-32">
        <div className="absolute inset-0">
          {/* World Spinning lazy image */}

          {!isVideoLoaded && (
            <LazyLoadComponent
              placeholder={<img src={WorldMap_LazyLoadImg} alt="" />}
            >
              <LazyLoadImage
                src={WorldMap_LazyLoadImg}
                alt="="
                className="absolute inset-0 w-full h-full object-cover"
              />
            </LazyLoadComponent>
          )}

          {/* World video */}
          <video
            className={`h-full w-full object-cover ${isVideoLoaded ? '' : 'hidden'}`}
            src={WorldMap}
            autoPlay
            loop
            muted
            onLoadedData={() => setIsVideoLoaded(true)} // Remove this line if you would like to look at the lazy image
          />

          <div
            className="absolute inset-0 bg-gray-500 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>


        {/* Page Title */}
        <div className="relative mx-auto max-w-7xl pt-24 px-4 sm:py-32 sm:pb-16 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">
            High-Risk-Lightning™
          </h1>
          <p className="text-6xl font-bold tracking-tight text-white md:text-7xl lg:text-8xl mapping">
            Mapping
          </p>
        </div>
      </div>
    );
  }

  function HRLMappingServices() {
    return (
      <>
        {/* HRL™ Mapping Services Section */}
        <div className="grid-base margin-large">
          <h2 className="subheader text-white">HRL™ Mapping Services</h2>
          <div className="grid-base2 columnNew">

            <div className='grid-base2-left'>
              <p className="text-lg text-gray-200">
                The final output of the HRL™ system is the mapping services
                integrated into our partner’s fiResponse platform. fiResponse is
                the leading wildfire behavior modeling software used by CalFire,
                FFS, and many other state agencies responsible for wildfire
                monitoring. An image of the FNN™ HRL™ dashboard for 2024 is shown
                below, with the HRL™ strike points and their associated fires next
                to them. This dashboard is where the live Firebird UAV footage is
                fed into for instant access by fire managers about potential
                ignition points.
              </p>
            </div>

            <div className='grid-base2-right'>
              <video
                className={`dash-demo-video ${isVideoLoaded ? '' : 'hidden'}`}
                src={DashDemo}
                autoPlay
                loop
                muted
                onLoadedData={() => setIsVideoLoaded(true)} // Remove this line if you would like to look at the lazy image
              />
            </div>
          </div>
          <div className="dashed-line"></div>
          <h3 className="slogan">
            Experience unparalleled convenience and efficiency with the FNN™ GIS integrated dashboards.
          </h3>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0"></div>
          </div>
        </div>
        <div className="grid-base grid-gap-large margin-large column2 grid-auto-rows padding">
          <p className="block" style={{color: '#28aae1'}}>
            Keep a comprehensive and real-time view of affected areas at your
            fingertips with our advanced alert system.
          </p>
          <p className="block" style={{color: '#28aae1'}}>
            Easily interpret and analyze historical data to track trends over
            time and make informed decisions.
          </p>
        </div>
      </>
    );
  }

  function HRLDashboard() {
    return (
      <>
        {/* HRL™ Dashboard Section */}
        <div className="grid-base grid-gap-small margin-large">
          <h2 className="subheader text-white hrlDashboard">HRL™ Dashboard</h2>
          <h3 className="slogan justify-left text-uppercase" style={{color: '#28aae1'}}>
            Below:
          </h3>
          <h3 className="slogan justify-left">
            This is an example of the HRL™ dashboard, powered by ESRI ArcGIS,
            which is used to search through lightining strikes and resulting
            fires
          </h3>
        </div>
        {/* HRL™ Dashboard Image */}
        <div className="HRL InterDashboard">
          <LazyLoadImage
            src={Dashboard}
            placeholderSrc={Dashboard_LazyLoad}
            alt={'HRL™ and Fire Dashboard'}
          />
        </div>
      </>
    );
  }

  function HRLStrikes() {
    return (
      <>
        {/* Strikes Section */}
        <div className="grid-base column3 margin-small">
          <p className="block text-white style">
            In a given day, thousands of lightning strikes can occur. It is
            crucial to differentiate those that are capable of starting a fire.
          </p>
          <img
            className="arrowMap"
            src={ArrowRight}
            alt="Arrow pointing right"
          />
          <p className="block text-white">
            High-Risk-Lightning™ (HRL™) strikes are the strikes responsible for
            igniting fires. Recognizing these HRL™ strikes allows firefighting
            services to focus on affected areas in need.
          </p>
        </div>
        <div className="grid-base column3">
          <div className="MapImage-overlay">
            <img
              className=""
              src={totalStrikes}
              alt="Total lightning strikes"
            />
            <div className="MapImage-text">TOTAL STRIKES</div>
          </div>
          <img
            className="arrowMap"
            src={ArrowRight}
            alt="Arrow pointing right"
          />
          <div className="MapImage-overlay">
            <img className="" src={hrlStrikes} alt="HRL™ lightning strikes" />
            <div className="MapImage-text">HRL™ STRIKES</div>
          </div>
        </div>

        <div className="location">*Lake Berryessa, CA 2020</div>
      </>
    );
  }

  return (
    <div>
      <IntroVideo />
      <HRLMappingServices />
      <HRLDashboard />
      <HRLStrikes />
    </div>
  );
}

export default HrlMapping;
