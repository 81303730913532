import { Fragment, useState } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, NavLink } from 'react-router-dom';
import { mainRoutes, secondaryRoutes } from '../routes';
import { useAuth } from '../Auth';
import { useNavigate } from 'react-router-dom';


export default function Header() {
  const navigate = useNavigate();

  const { isAuthenticated, username, first_name } = useAuth();

  const Logo = require('../img/FNNLogoWithWhiteFont-07.png');

  const [menuOpen, setMenuOpen] = useState(false);
  const handleAuthRedirection = () => {
    navigate('/login'); // Replace with your desired route
  };

  return (
    <Popover className="header bg-opacity-40" style={{ zIndex: '10' }}>
      <div className="flex items-center justify-between px-4 py-3 sm:px-6 md:justify-start md:space-x-10">
        <div>
          <Link to="/" className="flex">
            <span className="sr-only">Fire Neural Network</span>
            <img className="fnn-header-logo" src={Logo} alt="" />
          </Link>
        </div>
        <div className="flex flex-1 items-center justify-end md:justify-between">
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            {mainRoutes.map((route) => {
              return (
                <NavLink
                  to={route.to}
                  key={route.to}
                  className={({ isActive }) =>
                    isActive
                      ? 'text-base font-medium text-gray-200 hover:text-white'
                      : 'text-base font-medium text-gray-400 hover:text-white'
                  }
                >
                  {route.name}
                </NavLink>
              );
            })}
          </Popover.Group>
          <div className="flex justify-center items-center">
            {isAuthenticated ? (
               <div onClick={handleAuthRedirection} className="flex gap-0 flex-col items-center text-base text-gray-400 hover:text-white cursor-pointer pt-2">
               <svg
                 viewBox="0 0 16 16"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
                 style={{ width: '25px', height: '25px'}}
               >
                 <g id="SVGRepo_iconCarrier">
                   <path
                     d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z"
                     fill="#28aae1"
                   />
                   <path
                     d="M14 12C14 10.3431 12.6569 9 11 9H5C3.34315 9 2 10.3431 2 12V15H14V12Z"
                     fill="#28aae1"
                   />
                 </g>
               </svg>
               {first_name !== "" ? first_name : username.split("@")[0]}
             </div>
            ) : (
              <button
                className="text-base text-gray-400 hover:text-white cursor-pointer"
                onClick={handleAuthRedirection}
              >
                Login
              </button>
            )}
            <div
              onClick={() => setMenuOpen(!menuOpen)}
              className="cursor-pointer ml-1 inline-flex items-center justify-center rounded-md  border-transparent background-primary px-4 py-2 text-base font-medium text-white shadow-sm"
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={menuOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setMenuOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen h-full max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-black bg-opacity-95 py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 cursor-pointer"
                              onClick={() => setMenuOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 w-full px-8 sm:px-6">
                        <ul className="divide-y divide-gray-600">
                          {/* Only show the main routes when we're on mobile; otherwise they're in the header */}
                          {mainRoutes.map((menuItem) => (
                            <li
                              key={menuItem.to}
                              className="flex py-4 w-full md:hidden"
                              onClick={() => setMenuOpen(false)}
                            >
                              <Link
                                to={menuItem.to}
                                className="text-base font-medium text-gray-400 hover:text-white w-full h-full"
                              >
                                {menuItem.name}
                              </Link>
                            </li>
                          ))}
                          {/* Always show the secondary routes */}
                          {secondaryRoutes.map((menuItem) => (
                            <li
                              key={menuItem.to}
                              className="flex py-4 w-full"
                              onClick={() => setMenuOpen(false)}
                            >
                              <Link
                                to={menuItem.to}
                                className="w-full h-full text-base font-medium text-gray-400 hover:text-white"
                              >
                                {menuItem.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Popover>
  );
}
