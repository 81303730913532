import React from 'react';
import '../css/LoadingBar.css'; // Import the CSS for the spinner

const LoadingBar = () => {
  return (
    <div className="spinner-container">
      <div className="spinner-bar"></div>
    </div>
  );
};

export default LoadingBar;
