import { useEffect } from 'react';
import LoadingSpinner from '../backend/components/LoadingSpinner';

const HrlFirebird = () => {
  useEffect(() => {
    window.location.href = 'https://www.firebirddrone.com/';
  }, []);

  return <LoadingSpinner/>;
};

export default HrlFirebird;
