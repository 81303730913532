import CardGrid from '../components/card-grid';
import React, { useState, useEffect, useCallback } from 'react';
import '../css/news.css';
import LoadingSpinner from '../backend/components/LoadingSpinner'; // Import the loading spinner

import ParticleBackground from '../components/particlesbackground';
import { loadFull } from 'tsparticles';
import { loadPolygonMaskPlugin } from 'tsparticles-plugin-polygon-mask';
import type { Engine } from 'tsparticles-engine';


type Post = {
  title: string;
  href: string;
  date: string;
  datetime: string;
  image_url: any;
  new_tab: boolean;
  is_news: boolean;
  is_multimedia: boolean;
};



const News = () => {
  const [displayMode, setDisplayMode] = useState('all'); // Include 'videos' in the possible states

  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('/api/posts/', {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data: Post[] = await response.json();
        setPosts(data); // Assign the fetched posts to state
      } catch (err) {
        // Type assertion to 'Error' to access the message property
        if (err instanceof Error) {
          setError(err.message); // Handle error
        } else {
          setError('An unknown error occurred'); // Fallback for unknown errors
        }
      } finally {
        setLoading(false); // End loading state
      }
    };

    fetchPosts();
  }, []); // Empty dependency array to run once on mount

  const handleButtonClick = (mode: any) => {
    setDisplayMode(displayMode === mode ? 'all' : mode);
  };

  // Filter posts based on the display mode
  const filteredPosts = (posts || []).filter((post) => {
    if (displayMode === 'all') return true;
    if (displayMode === 'news') return post.is_news;
    if (displayMode === 'stories') return !post.is_news && !post.is_multimedia;
    return post.is_multimedia; // Filter for multimedia posts (videos)
  });

  // Sort posts
  const sortedPosts = filteredPosts.sort(
    (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
  );

  // Button styling
  const getButtonClass = (mode: any) => {
    return `btn btn-light custom-btn${displayMode === mode ? ' grey-button' : ''
      }`;
  };

  const particlesInit = useCallback(async (main: Engine) => {
    await loadFull(main);
    await loadPolygonMaskPlugin(main);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const [heightTopParticles, setHeightTopParticles] = useState(width * 0.36);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      setHeightTopParticles(width * 0.36);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return (
    <div className='relative overflow-hidden'>
      <div className="text-center mt-40">
      <ParticleBackground
          particlesInitBackground={particlesInit}
          id="topParticles"
          number={200}
          aspectRatio={width / heightTopParticles}
        />
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Latest News
        </h2>
        <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
          Internal and External publications about FNN
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        <button
          className={getButtonClass('news')}
          onClick={() => handleButtonClick('news')}
          style={{ zIndex: '1' }}
        >
          News
        </button>
        <button
          className={getButtonClass('stories')}
          onClick={() => handleButtonClick('stories')}
          style={{ marginLeft: '15px', zIndex: '1'}}
        >
          Stories
        </button>
        {/* New button for videos */}
        <button
          className={getButtonClass('multimedia')}
          onClick={() => handleButtonClick('multimedia')}
          style={{ marginLeft: '15px', zIndex: '1' }}
        >
          Multimedia
        </button>
      </div>
      {loading ? (<LoadingSpinner />) : (<CardGrid cards={sortedPosts} />)}

    </div>
  );
};

export default News;
