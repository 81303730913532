import { EnvelopeIcon } from '@heroicons/react/24/outline';
import '../css/subscribe-form.css';

const iframeSrc = "https://d427d68c.sibforms.com/serve/MUIFAGA7vlgiW_lEgndMCKY9qWOv6lhz80YRtZfjc0bMuwz9zIy2RFMw-cTycSaX_t16sT5CrkndWurYUIdYveVnQx73aewFHTrxWwvJETNtD8uIgYO0i42pStNOcRZaH64p3OwGMHMgBBPBigx32dzsJHtmDueTUoJ-zAjRaNFBRfty4bG97cB2x9ZjBv6qeGCmyG4SXg2seIdq";


export default function SubscribeForm() {
  return (
    <div className="iframe-container">
          <iframe className="iframe" src={iframeSrc} frameBorder="0" scrolling="no" allowFullScreen style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}></iframe>
    </div>
  );
}