import '../css/home.css';
import { useCallback, useEffect, useState } from 'react';
import { loadFull } from 'tsparticles';
import { loadPolygonMaskPlugin } from 'tsparticles-plugin-polygon-mask';
import type { Engine } from 'tsparticles-engine';
import Sponsors from '../components/sponsors';

import ParticleBackground from '../components/particlesbackground';

import fnnLetters from '../img/FNNLogoWhiteFont-03.png'

function Home() {
  const LightForest = require('../img/home-page/Homepage_lightning.png');
  const particlesInit = useCallback(async (main: Engine) => {
    await loadFull(main);
    await loadPolygonMaskPlugin(main);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const [heightTopParticles, setHeightTopParticles] = useState(width * 0.36);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      setHeightTopParticles(width * 0.36);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  const networkMountains = require('../img/home-page/FNNhomepg.png');

  const learnMoreData = [
    {
      title: 'HRL™ Detector',
      description:
        'The High-Risk-Lightning™ detector has a dual-band design that allows it to provide precise current duration and charge transfer measurements. These capabilities allow for 3-D mapping with superior accuracy and detection efficiency.',
      imgUrl: require('../img/home-page/Homepage_installation_new_logo.jpg'),
      imgAlt: 'HRL Detector',
      link: '/hrl-detector',
    }
  ];

  function HomeImage() {
    return (
      <>
        {/* FNN Title and Image */}
        <div className="text-white text-center home">
          {/* <div className="banner">
            <p className="banner-text">
              Wildfires emit about 1/5 of global CO2 emissions! Fire Neural
              Network (FNN) is the only entity that can detect wildfires before
              they become one. Join FNN on its mission to save lives, protect
              property, and help fight against climate change!{' '}
              <span style={{ color: '#e5640d' }}>1234567890</span> Wildfires
              emit about 1/5 of global CO2 emissions! Fire Neural Network (FNN)
              is the only entity that can detect wildfires before they become
              one. Join FNN on its mission to save lives, protect property, and
              help fight against climate change!
            </p>
          </div> */}

          <div className='fnnLettersDiv'>
            <img
              className="fnnLetters"
              src={fnnLetters}
              alt=""
            />
          </div>

          <div className="relative top-particles-container">
            <ParticleBackground
              particlesInitBackground={particlesInit}
              id="topParticles"
              number={125}
              aspectRatio={width / heightTopParticles}
            />
            <img src={networkMountains} alt="" className="home-img-top" />
          </div>
          {/* Fire Slogan */}
          <div className="pt-8 pb-8">
            <h2 className="title">
              Stay Ahead of the <i>Storm ℠</i>
            </h2>
            {/* Semi-Rectangle Text Section */}
            <div className="grid-container">
              <div className="grid-group">
                <span className="separator"></span>
                <p className="semi-rectangle">
                  Lightning and atmospheric events are the <strong>leading</strong> cause of power
                  outages in the US. FNN™ provides real-time detection of high-risk strikes that
                  leads to these power inefficiencies.
                </p>
              </div>
              <div className="grid-group-flip">
                <p className="semi-rectangle-flip">
                  FNN™ detects High-Risk-Lightning™ using its proprietary
                  detector and pairs this information with real-time environmental data using AI
                  signal processing to pinpoint fault locations within seconds.
                </p>
                <span className="separator"></span>
              </div>
            </div>
            {/* Intelligence Slogan */}
            <h3 className="slogan">
              FNN™ delivers actionable intelligence in real time.
            </h3>
            <div className="line"></div>
          </div>
        </div>
      </>
    );
  }

  function Benefits() {
    return (
      <>
        {/* Forest Lightning Image */}
        <div className="relative">
          <img
            className="LightForest"
            src={LightForest}
            alt="Forest with Lightning in the Background"
          />
        </div>
        <div className="relative">
          <ParticleBackground
            particlesInitBackground={particlesInit}
            id="benefitsParticles"
            number={100}
          />
          {/* Background and Benefits Section */}
          <div className="bg-ben-container">
            <div className="background-container">
              <h2 className="background-title">Background</h2>
              <p className="background-text">
                Lightning is the #1 cause of power interruptions and wildfires,
                in terms of area burned. HRL™ is the culprit for both major events with power
                utilities and forestry agencies. However it only represents a mere 5% of all lightning
                strikes. HRL™ lasts 1000x longer creating excessive heating that damages power assets.
                FNN™ pinpoints these strikes within 100 feet.
              </p>
            </div>
            <div className="benefits-container">
              <h2 className="background-title">Benefits</h2>
              <div className="benefitsNum-container">
                <div className="flex">
                  <div className="number-circle">01</div>
                  <p className="background-text">
                    Unlike others, FNN™ alerts before there are visible signs of
                    a fire
                  </p>
                </div>
                <div className="flex">
                  <div className="number-circle">02</div>
                  <p className="background-text">
                    FNN™ can track and deliver AI-enabled alerts on power asset
                    health after HRL™ strikes
                  </p>
                </div>
                <div className="flex">
                  <div className="number-circle">03</div>
                  <p className="background-text">
                    Decreases firefighting costs, utility company losses, and
                    insurance payouts
                  </p>
                </div>
                <div className="flex">
                  <div className="number-circle">04</div>
                  <p className="background-text">
                    Empower Power Delivery and Emergency Preparedness teams to
                    optimize dispatch and construction standards
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Accuracy Slogan */}
          <h2 className="instant">
            Instantaneous Trigger with Pinpoint Accuracy℠
          </h2>
        </div>
      </>
    );
  }

  // Learn More Component
  function LearnMore(props: any) {
    const { description, imgUrl, imgAlt, link } = props;
    return (
      <>
        <div className="image-description-learnmore-container">
          {/* Image */}
          <img className="TwoHappyBois" src={imgUrl} alt={imgAlt} />
          {/* Overlayed Text and Button */}
          <div className="description-image-overlay">
            <div className="description-overlay">{description}</div>
            <a href={link} className="learnMore-button">
              Learn More
            </a>
          </div>
        </div>
      </>
    );
  }

  // Map the learnMoreData array to LearnMore components
  const learnMoreElements = learnMoreData.map((data) => (
    <LearnMore
      key={data.title}
      description={data.description}
      imgUrl={data.imgUrl}
      imgAlt={data.imgAlt}
      link={data.link}
    />
  ));

  return (
    <div className="Home">
      {/* Home Page Image and Text */}
      <HomeImage />
      <Benefits />
      {learnMoreElements}
      {/* Sponsors*/}
      <Sponsors particlesInitBackground={particlesInit} />
    </div>
  );
}

export default Home;
