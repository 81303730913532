import React, { useState, useEffect, useCallback } from 'react';
import Gallery from '../components/gallery';
import ParticleBackground from '../components/particlesbackground';
import { loadFull } from 'tsparticles';
import { loadPolygonMaskPlugin } from 'tsparticles-plugin-polygon-mask';
import type { Engine } from 'tsparticles-engine';

// const Video2 = require('../img/mission/FNN-Mission.mp4');

// Interface for reading image
type MissionPhoto = {
  imgAlt: string;
  img: any;
};

function Mission() {

  const [galleryData, setGalleryData] = useState<MissionPhoto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchGalleryPhotos = async () => {
    try {
      const response = await fetch('/api/missiongallery/', {
        method: 'GET',
        credentials: 'same-origin',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data: MissionPhoto[] = await response.json();
      setGalleryData(data); // Assign the fetched gallery data to state
      // console.log(data)
    } catch (err) {
      // Type assertion to 'Error' to access the message property
      if (err instanceof Error) {
        setError(err.message); // Handle error
      } else {
        setError('An unknown error occurred'); // Fallback for unknown errors
      }
    } finally {
      setLoading(false); // End loading state
    }
  };

  useEffect(() => {
    fetchGalleryPhotos();
  }, []); // Empty dependency array to run once on mount

  const particlesInit = useCallback(async (main: Engine) => {
    await loadFull(main);
    await loadPolygonMaskPlugin(main);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const [heightTopParticles, setHeightTopParticles] = useState(width * 0.36);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      setHeightTopParticles(width * 0.36);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return (
    <div>
      <div className="relative overflow-hidden">
        <ParticleBackground
          particlesInitBackground={particlesInit}
          id="topParticles"
          number={125}
          aspectRatio={width / heightTopParticles}
        />

        {/* Mission Background Image */}
        <div className="grid-mission">
          {/* Mission Statement */}
          <h1 className="our-mission font-bold tracking-tight text-white">
            Our <span className="textColor">Mission</span> is to Save Lives, Protect Property, 
            and Increase Operational Efficiency.
          </h1>
          {/* Mission Youtube Video */}
          <div className="youtube-mission">
            <iframe
              width="150vh"
              height="315"
              src="https://www.youtube.com/embed/77wEToiX9oA"
              title="FNN YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>

            {/* <video className="video-diagram" controls>
              <source src={Video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
          </div>
          {/* Mission Texts */}
          <div className="grid-block text-white">
            <p className="block-overlay">
              FNN™ utilizes novel lightning detectors capable of
              measuring the attributes of High-Risk-Lightning™ (HRL™), including
              long continuing currents and charge transfer that lead to heating.
              Fire agencies can efficiently target this small proportion of HRL™
              strikes. In addition, the smart detectors are able to map
              lightning in 3D, leading to superior detection efficiency and
              location accuracy, thereby facilitating both a quicker and more
              efficient inspection of high-risk ground strikes.
            </p>
            <p className="block-overlay">
              This outcome is accomplished using a machine learning algorithm to
              analyze electric field waveforms from our lightning detectors to
              identify the presence of long-continuing currents. This unique
              characterization of lightning strikes is combined with information
              about weather and fuel attributes derived from satellite and
              airborne sensors to enable a more thorough understanding of the
              conditions under which lightning ignitions occur.
            </p>

          </div>
        </div>
      </div>
      <Gallery data={galleryData} isLoading={loading} />
    </div>
  );
}

export default Mission;
